<template>
<div  style="position: relative">
  <base-nav
    id="nav"
    type="default"
    effect="dark"
    title="My Property Guru"
    expand
  >
  <img v-if="$auth.isAuthenticated" src="https://pmselondonltd.com/img/brand/pmse.png" alt="" height="50">
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <ul class="navbar-nav align-items-lg-center">
      <li class="nav-item">
        <router-link class="nav-link" v-if="!$auth.isAuthenticated" to="/"
          >Home</router-link
        >
      </li>
      <li class="nav-item">
        <router-link class="nav-link" v-if="!$auth.isAuthenticated" to="/about"
          >About</router-link
        >
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link"
          v-if="$auth.isAuthenticated"
          to="/profile"
        >
          <i class="fa fa-user-circle" aria-hidden="true"></i>Profile
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link"
          v-if="$auth.isAuthenticated"
          to="/buildings"
        >
          <i class="fa fa-building" aria-hidden="true"></i>Properties
        </router-link>
      </li>

      <base-dropdown v-if="$auth.isAuthenticated" tag="li" title="People" icon="fa fa-users">
        <router-link
          class="dropdown-item"
          v-if="$auth.isAuthenticated"
          to="/tenants"
        >
          <i class="fa fa-user" aria-hidden="true"></i>
          Tenants
        </router-link>
        <router-link
          class="dropdown-item"
          v-if="$auth.isAuthenticated"
          to="/landlords"
        >
          <i class="fa fa-user" aria-hidden="true"></i>
          Landlords
        </router-link>
      </base-dropdown>

      <li class="nav-item">
        <router-link
          class="nav-link"
          v-if="$auth.isAuthenticated"
          to="/reports"
        >
          <i class="fa fa-book" aria-hidden="true"></i>Reports
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link"
          v-if="$auth.isAuthenticated"
          to="/documents"
        >
          <i class="fa fa-file-text" aria-hidden="true"></i>Documents
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link"
          v-if="$auth.isAuthenticated"
          to="/defects"
        >
          <i class="fa fa-wrench" aria-hidden="true"></i>Defects
        </router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" v-if="$auth.isAuthenticated" to="/admin">
<i class="fa fa-cog" aria-hidden="true"></i>Admin
        </router-link>
      </li>
    </ul>

    <!-- Check that the SDK client is not currently loading before accessing is methods -->
      <ul class="navbar-nav align-items-lg-center ml-lg-auto" v-if="!$auth.loading">

          <a class="nav-link text-secondary" v-if="$auth.isAuthenticated">
            Hello {{ $auth.user.name }}!
          </a>
        <div class="btn-toolbar">
          <li class="nav-item">
            <button
              type="button"
              class="btn btn-success btn-group mr-2"
              v-if="!$auth.isAuthenticated"
            >
              Sign up
            </button>
            <button
              type="button"
              class="btn btn-primary btn-group mr-2"
              v-if="!$auth.isAuthenticated"
              @click="login"
            >
              Log in
            </button>
            <button
              type="button"
              class="btn btn-primary btn-group"
              v-if="$auth.isAuthenticated"
              @click="logout"
            >
              Log out
            </button>
          </li>
        </div>
      </ul>
  </base-nav>
</div>
</template>

<script>
import BaseNav from "@/components/BaseNav";
import BaseDropdown from "@/components/BaseDropdown";

export default {
  name: "Navbar",
  components: {
    BaseNav,
    BaseDropdown,
  },
  methods: {
    // Log the user in
    login() {
      this.$auth.loginWithRedirect();
    },
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
  },
};
</script>
