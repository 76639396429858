<template>
  <div class="landlords">
    <section class="section-shaped my-0">
      <div class="shape shape-style-1 shape-default shape-skew">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <h1 class="display-1 text-secondary">
        <i class="fa fa-user-circle" aria-hidden="true"></i> Landlords
      </h1>
      <div class="container">
        <div class="jumbotron">
          <div class="row">
            <div class="col text-left">
              <a
                class="btn btn-success"
                data-toggle="collapse"
                href="#newLandlord"
                role="button"
                aria-expanded="false"
                aria-controls="collapse"
              >
                <svg
                  width="1em"
                  height="1em"
                  viewBox="0 0 16 16"
                  class="bi bi-plus-circle-fill"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"
                  /></svg
                >
                <span class="text">New Landlord</span>
              </a>
            </div>
            <div class="col text-right">
              <button
                type="button"
                class="btn btn-primary"
                @click="downloadCSV"
              >
                <span class="text">Download</span>&#32;
                <svg
                  width="1em"
                  height="1em"
                  viewBox="0 0 16 16"
                  class="bi bi-file-earmark-arrow-down-fill"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2 2a2 2 0 0 1 2-2h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm7 2l.5-2.5 3 3L10 5a1 1 0 0 1-1-1zm-.5 3.5a.5.5 0 0 0-1 0v3.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 2a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 11.293V7.5z"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div class="row">
            <p></p>
          </div>
          <div class="row">
            <div class="col text-left">
              <div class="collapse" id="newLandlord">
                <div class="jumbotron jumbotron-fluid">
                  <form class="container">
                    <div class="form-row">
                      <div class="form-group col">
                        <label for="landlordName">First Name:</label>
                        <input
                          type="text"
                          class="form-control"
                          id="landlordName"
                          ref="landlordName"
                          placeholder="First Name"
                        />
                      </div>
                      <div class="form-group col">
                        <label for="landlordFamilyName">Family Name:</label>
                        <input
                          type="text"
                          class="form-control"
                          id="landlordFamilyName"
                          ref="landlordFamilyName"
                          placeholder="Family Name"
                        />
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col">
                        <label for="landlordEmail">Email:</label>
                        <input
                          type="email"
                          class="form-control"
                          id="landlordEmail"
                          ref="landlordEmail"
                          placeholder="Email"
                        />
                      </div>
                      <div class="form-group col">
                        <label for="landlordNumber">Mobile Number:</label>

                        <div v-for="index in mobile_nos" :key="index">
                          <input
                            type="tel"
                            class="form-control"
                            id="landlordNumber"
                            ref="landlordNumber"
                            placeholder="Mobile Number"
                          />
                          <br />
                        </div>
                        <a
                          class="text-danger"
                          role="button"
                          @click="mobile_nos--"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-dash-circle-fill"
                            viewBox="0 0 16 16"
                          >
                            <path
                              d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7z"
                            />
                          </svg>
                        </a>
                        <a
                          class="text-success"
                          role="button"
                          @click="mobile_nos++"
                        >
                          <svg
                            width="1em"
                            height="1em"
                            viewBox="0 0 16 16"
                            class="bi bi-plus-circle-fill"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"
                            />
                          </svg>
                        </a> 
                      </div>
                    </div>
                    <button
                      type="submit"
                      class="btn btn-success"
                      @click.prevent="createLandlord"
                    >
                      Create
                    </button>
                  </form>
                </div>
              </div>
            </div>
            <div class="row">
              <p></p>
            </div>
          </div>
          <div class="row">
            <table class="table table-hover">
              <thead class="thead-dark">
                <tr>
                  <th scope="col">First Name</th>
                  <th scope="col">Family Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Mobile Number</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody v-for="landlord in landlords" :key="landlord.id">
                <tr>
                  <td>{{ landlord.first_name }}</td>
                  <td>{{ landlord.family_name }}</td>
                  <td>
                    <a :href="'mailto:' + landlord.email">{{
                      landlord.email
                    }}</a>
                  </td>
                  <td>
                    <a
                      v-for="number in landlord.mobile_no.split(',')"
                      :key="number"
                      :href="'tel:' + number"
                      >{{ number }}<br /></a
                    >
                  </td>
                  <td>
                    <router-link
                      :to="{
                        name: 'LandlordProfile',
                        query: { landlordId: landlord.id },
                      }"
                    >
                      <button
                        class="btn btn-primary"
                        type="button"
                        name="button"
                      >
                        <svg
                          width="1em"
                          height="1em"
                          viewBox="0 0 16 16"
                          class="bi bi-person-lines-fill"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm7 1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm2 9a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"
                          /></svg
                        >&#32;
                        <span class="text">Full Profile</span>
                      </button>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { ExportToCsv } from "export-to-csv";
import app_config from "../../app_config.json";
import getEnv from "@/utils/env";

export default {
  name: "Landlords",
  data() {
    return {
      landlords: "",
      token: "",
      people_api_url: app_config[getEnv("VUE_APP_ENV")].people_api_url,
      mobile_nos: 1,
    };
  },
  props: {
    buildingId: Number,
  },
  created() {
    let loader = this.$loading.show({
      // Optional parameters
      container: this.fullPage ? null : this.$refs.formContainer,
      canCancel: true,
      onCancel: this.onCancel,
    });

    this.callApi(loader);
  },
  methods: {
    async createLandlord() {
      let phonenumbers = "";
      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: true,
        onCancel: this.onCancel,
      });

      const url = this.people_api_url + "landlord/";

      this.$refs.landlordNumber.forEach(function(phonenumber) {
        phonenumbers += phonenumber.value + ",";
      });

      phonenumbers = phonenumbers.slice(0, -1);

      axios
        .post(
          url,
          {
            first_name: this.$refs.landlordName.value.trim(),
            family_name: this.$refs.landlordFamilyName.value.trim(),
            email: this.$refs.landlordEmail.value.trim(),
            mobile_no: phonenumbers,
          },
          {
            headers: {
              Authorization: `Bearer ${this.token}`, // send the access token through the 'Authorization' header
            },
          }
        )
        .then(() => {
          this.$refs.landlordName.value = "";
          this.$refs.landlordFamilyName.value = "";
          this.$refs.landlordEmail.value = "";
          this.$refs.landlordNumber.value = "";
          this.callApi(loader);
        });
    },
    downloadCSV() {
      const options = {
        fieldSeparator: ",",
        quoteStrings: '"',
        decimalSeparator: ".",
        showLabels: true,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        filename: "landlords",
        // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
      };

      const csvExporter = new ExportToCsv(options);

      csvExporter.generateCsv(this.landlords);
    },
    async callApi(loader) {
      // Get the access token from the auth wrapper
      this.token = await this.$auth.getTokenSilently();
      const url = this.people_api_url + "landlord/";

      // Use Axios to make a call to the API
      const { data } = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${this.token}`, // send the access token through the 'Authorization' header
        },
      });

      this.landlords = data;
      this.$forceUpdate();
      loader.hide();
    },
  },
};
</script>
