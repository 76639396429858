<template>
  <div class="Documents">
                <section class="section-shaped my-0">
      <div class="shape shape-style-1 shape-default shape-skew">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    <h1 class="display-1 text-secondary">
      <i class="fa fa-file-text" aria-hidden="true"></i> Documents
    </h1>

    <div class="jumbotron text-left container">
      <Documents ref="documents" :token="token" :enableUpload="false" />
    </div>
                </section>
  </div>
</template>

<script>

import Documents from "@/components/Documents";

export default {
  name: 'DocumentsView',
  components: {
    Documents
  },
  data() {
    return {
      token: ''
    }
  },
  mounted() {
    this.init()
  },
  props: {
    file: String,
  },
  methods: {
    async init(){
      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: true,
        onCancel: this.onCancel,
      });
      this.token = await this.$auth.getTokenSilently()
      this.$refs.documents.refresh(loader,this.token,{file_name: this.$props.file})
    }
  }
}
</script>