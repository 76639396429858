<template>
  <div class="notes">
    <h3><i class="fa fa-sticky-note" aria-hidden="true"></i> Notes</h3>
    <table class="table table-hover">
      <tbody v-for="note in notes" :key="note.id">
      <tr>
        <td style="width: 70%">{{ note.notes }}</td>
        <td style="width: 20%">{{ note.author }} <br>{{ new Date(note.date_time).toLocaleDateString("en-GB").replaceAll("/","-") + " "
        + new Date(note.date_time).toLocaleTimeString("en-GB").slice(0,-3)}}
        </td>
        <td style="width: 10%">
          <button v-b-tooltip.hover.top  title="Delete Note" class="btn btn-danger" type="button" @click="deleteNote(note.id)">
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-trash-fill" fill="currentColor"
                 xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                    d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z"/>
            </svg>
          </button>
        </td>
      </tr>
      </tbody>
    </table>
    <a class="btn btn-success" data-toggle="collapse" href="#newNote" role="button" aria-expanded="false"
       aria-controls="collapse">
      <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus-circle-fill" fill="currentColor"
           xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd"
              d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"/>
      </svg>&#32;
      <span class="text">New Note</span>
    </a>
    <div class="collapse" id="newNote">
      <div class="jumbotron jumbotron-fluid">
        <form class="container">
          <div class="form-row">
            <div class="form-group col">
              <h2>Note:</h2>
              <textarea class="form-control" ref="notesText" rows="8" cols="80"></textarea>
            </div>
          </div>
          <button type="submit" class="btn btn-success" @click.prevent="createNote">Create</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios'

export default {
  name: 'Notes',
  data() {
    return {
      notes: ''
    }
  },
  props: {
    url: String,
    token: String,
    id: Number,
    id_column: String
  },
  methods: {
    async createNote() {
      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: true,
        onCancel: this.onCancel
      })

      let note = {}
      note[this.$props.id_column] = this.$props.id
      note = {
        ...note,
        ...{
          date_time: new Date().toISOString(),
          notes: this.$refs.notesText.value,
          author: this.$auth.user.name
        }
      }

      axios
          .post(
              this.$props.url,
              note,
              {
                headers: {
                  Authorization: `Bearer ${this.token}` // send the access token through the 'Authorization' header
                }
              }
          )
          .then(
              () => {
                this.refresh(loader)
              }
          )
    },
    async deleteNote(notesId) {
      const url = this.$props.url + '/' + notesId

      if (confirm('are you sure?')) {
        let loader = this.$loading.show({
          // Optional parameters
          container: this.fullPage ? null : this.$refs.formContainer,
          canCancel: true,
          onCancel: this.onCancel
        })

        axios
            .delete(url, {
              headers: {
                Authorization: `Bearer ${this.token}` // send the access token through the 'Authorization' header
              }
            })
            .then(
                () => {
                  this.refresh(loader)
                }
            )
      }
    },
    async getNotes() {
      //Use Axios to make a call to the API
      const notesData = await axios.get(this.$props.url, {
        headers: {
          Authorization: `Bearer ${this.token}` // send the access token through the 'Authorization' header
        }
      })

      this.notes = notesData['data']
      return this.notes.length
    },
    async refresh(loader) {
      this.getNotes()
      this.$forceUpdate()
      loader.hide()
    }
  }
}

</script>
