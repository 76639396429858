<template>
  <div class="defects">
    <h3><i class="fa fa-wrench" aria-hidden="true"></i> Defects</h3>
    <div class="form-check">
      <input class="form-check-input" type="checkbox" value="" id="showCompleted" ref="showCompleted"
             v-model="showCompleted">
      <label class="form-check-label" for="showCompleted">
        Show Completed
      </label>
    </div>
    <table class="table table-hover">
      <thead class="thead-dark">
      <tr>
        <th v-if="enableCreate" style="width: 5%">ID</th>
        <th v-else style="width: 5%">Building</th>
        <th style="width: 5%">Reference</th>
        <th style="width: 7%">Creation Date</th>
        <th style="width: 5%">Location</th>
        <th style="width: 25%">Defect</th>
        <th style="width: 4%">Priority</th>
        <th style="width: 4%">Status</th>
        <th style="width: 10%"></th>
      </tr>
      </thead>
      <tbody v-for="defect in filteredDefects" :key="defect.id">
      <tr>
        <td v-if="enableCreate">{{ defect.id }}</td>
        <td v-else>{{ defect.no_name }} {{ defect.street }}, {{ defect.borough }}, {{ defect.postcode }}</td>
        <td>{{ defect.reference }}</td>
        <td>{{ new Date(defect.creation_date).toLocaleDateString("en-GB").replaceAll("/","-") }}</td>
        <td>{{ defect.location }}</td>
        <td>{{ defect.defect }}</td>
        <td>
          <div v-if="defect.priority === 'LOW'">
            <p style="font-size: 70%" class="btn btn-success text-secondary">LOW</p>
          </div>
          <div v-else-if="defect.priority === 'MEDIUM'">
            <a style="font-size: 70%" class="btn btn-warning text-secondary">MED</a>
          </div>
          <div v-else>
            <a style="font-size: 70%" class="btn btn-danger  text-secondary">HI</a>
          </div>
        </td>
        <td>
          <div v-if="defect.status === 'CREATED'">
            <p style="font-size: 70%" class="btn btn-primary text-secondary">CREATED</p>
          </div>
          <div v-else-if="defect.status === 'IN_PROGRESS'">
            <a style="font-size: 70%" class="btn btn-info text-secondary">IN PROGRESS</a>
          </div>
          <div v-else>
            <a style="font-size: 70%" class="btn btn-success text-secondary">COMPLETED</a>
          </div>
        </td>
        <td>
          <a class="btn btn-primary" data-toggle="collapse" :href="'#defectDetails-'+defect.id" role="button"
             aria-expanded="false"
             aria-controls="collapse">
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus-circle-fill" fill="currentColor"
                 xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"/>
            </svg>&#32;
            <span class="text">Details</span>
          </a>
        </td>
      </tr>
      <tr class="collapse" :id="'defectDetails-'+defect.id">
        <td>
          <p>
            Completion<br>
            Date:<br>
            {{ defect.completion_date ? new Date(defect.completion_date).toLocaleDateString("en-GB").replaceAll("/","-") : ''}}
          </p>
        </td>
        <td colspan="6">Action:
          <div v-if="defect.action">
            {{ defect.action }}
          </div>
          <div v-else>
            <input class="form-control" type="text" :id="'defectAction-' + defect.id"
                   :ref="'defectAction-' + defect.id">
          </div>
        </td>
        <td col-md-2>
          <div class="btn-toolbar">
            <button class="btn btn-danger btn-block" type="button" @click="deleteDefect(defect.id)">
              <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-trash-fill" fill="currentColor"
                   xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                      d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z"/>
              </svg>&#32;
              <span class="text">Delete</span>
            </button>
              <button class="btn btn-primary btn-block" type="button" @click="editText()"
                        v-if="defect.status != 'COMPLETED'" v-b-modal="'modal-'+defect.id">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil-fill" fill="currentColor"
                     xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                        d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
                </svg>&#32;
                <span class="text">Edit</span>
              </button>
              <b-modal size="lg" :id="'modal-'+defect.id" :ref="'modal-'+defect.id" title="Edit Defect" hide-footer=true>
                <form class="container">
                  <div class="form-row">
                    <div class="form-group col">
                      <label :for="'editDefectPriority-'+defect.id">Defect Priority:</label>
                      <select class="form-control" :id="'editDefectPriority-'+defect.id" :ref="'editDefectPriority-'+defect.id" :value="defect.priority">
                        <option>HIGH</option>
                        <option>MEDIUM</option>
                        <option>LOW</option>
                      </select>
                    </div>
                    <div class="form-group col">
                      <label :for="'editDefectReference-'+defect.id">Reference:</label>
                      <input class="form-control" type="text" :id="'editDefectReference-'+defect.id" :ref="'editDefectReference-'+defect.id" :value="defect.reference">
                    </div>
                    <div class="form-group col">
                      <label :for="'editDefectLocation-'+defect.id">Location:</label>
                      <input class="form-control" type="text" :id="'editDefectLocation-'+defect.id" :ref="'editDefectLocation-'+defect.id" :value="defect.location">
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col">
                      <label :for="'editDefectDescription-'+defect.id">Defect:</label>
                      <input class="form-control" type="text" :id="'editDefectDescription-'+defect.id" :ref="'editDefectDescription-'+defect.id" :value="defect.defect">
                    </div>
                  </div>
                  <button type="submit" class="btn btn-success" @click.prevent="updateDefect(defect.id)">Update</button>&#32;
                  <button type="submit" class="btn btn-danger" @click.prevent="hideModal(defect.id)">Cancel</button>
                </form>
              </b-modal>
            <button class="btn btn-success btn-block" type="button" @click="completeDefect(defect.id)"
                    v-if="defect.status != 'COMPLETED'">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                   class="bi bi-calendar-check-fill" viewBox="0 0 16 16">
                <path
                    d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5zM16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2zm-5.146-5.146l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708.708z"/>
              </svg>&#32;
              <span class="text">Complete</span>
            </button>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
    <a v-if="enableCreate" class="btn btn-success" data-toggle="collapse" href="#newDefect" role="button"
       aria-expanded="false"
       aria-controls="collapse">
      <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus-circle-fill" fill="currentColor"
           xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd"
              d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"/>
      </svg>&#32;
      <span class="text">New Defect</span>
    </a>
    <div class="collapse" id="newDefect">
      <div class="jumbotron jumbotron-fluid">
        <form class="container">
          <div class="form-row">
            <div class="form-group col">
              <label for="defectPriority">Defect Priority:</label>
              <select class="form-control" id="defectPriority" ref="defectPriority">
                <option>HIGH</option>
                <option>MEDIUM</option>
                <option>LOW</option>
              </select>
            </div>
            <div class="form-group col">
              <label for="defectReference">Reference:</label>
              <input class="form-control" type="text" id="defectReference" ref="defectReference">
            </div>
            <div class="form-group col">
              <label for="defectLocation">Location:</label>
              <input class="form-control" type="text" id="defectLocation" ref="defectLocation">
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col">
              <label for="defectDescription">Defect:</label>
              <input class="form-control" type="text" id="defectDescription" ref="defectDescription">
            </div>
            <div class="form-group col">
            </div>
          </div>
          <button type="submit" class="btn btn-success" @click.prevent="createDefect()">Create</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios'
import getEnv from '@/utils/env'
import app_config from "../../app_config.json";

export default {
  name: 'Defects',
  data() {
    return {
      defects: [],
      defects_api_url: app_config[getEnv('VUE_APP_ENV')].defects_api_url,
      showCompleted: ''
    }
  },
  props: {
    url: {
      type: String,
      default: ''
    },
    enableCreate: {
      type: Boolean,
      default: true
    },
    token: String
  },
  computed: {
    filteredDefects: function () {
      if (this.showCompleted) return this.defects
      else return this.defects.filter(function (d) {
        return d.status != 'COMPLETED'
      })
    }
  },
  methods: {
    hideModal(id) {
      this.$refs['modal-'+id][0].hide()
    },
    async updateDefect(id){
      const url = this.defects_api_url + id

      if (confirm('are you sure?')) {
        let loader = this.$loading.show({
          // Optional parameters
          container: this.fullPage ? null : this.$refs.formContainer,
          canCancel: true,
          onCancel: this.onCancel,
        });

        let defect = {
          reference: `'${this.$refs['editDefectReference-'+id][0].value}'`,
          location: `'${this.$refs['editDefectLocation-'+id][0].value}'`,
          defect: `'${this.$refs['editDefectDescription-'+id][0].value}'`,
          priority: `'${this.$refs['editDefectPriority-'+id][0].value}'`
        }

        axios.patch(
            url,
            defect,
            {
              headers: {
                Authorization: `Bearer ${this.token}` // send the access token through the 'Authorization' header
              }
            }
        ).then(
            () => {
              this.refresh(loader)
              this.$refs['modal-'+id][0].hide()
            }
        )
      }
    },
    async completeDefect(id) {
      const url = this.defects_api_url + id
      var d = new Date();

      if (confirm('are you sure?')) {
        let loader = this.$loading.show({
          // Optional parameters
          container: this.fullPage ? null : this.$refs.formContainer,
          canCancel: true,
          onCancel: this.onCancel,
        });

        axios.patch(
            url,
            {
              completion_date: `'${d.getUTCFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate()}'`,
              action: `'${this.$refs['defectAction-' + id][0].value.trim()}'`,
              status: `'COMPLETED'`
            },
            {
              headers: {
                Authorization: `Bearer ${this.token}` // send the access token through the 'Authorization' header
              }
            }
        ).then(
            () => {
              this.refresh(loader)
            }
        )
      }
    },
    async createDefect() {
      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: true,
        onCancel: this.onCancel
      })

      let defect = {
        building_id: this.$props.url,
        reference: this.$refs.defectReference.value,
        location: this.$refs.defectLocation.value,
        defect: this.$refs.defectDescription.value,
        priority: this.$refs.defectPriority.value
      }

      axios
          .post(
              this.defects_api_url,
              defect,
              {
                headers: {
                  Authorization: `Bearer ${this.token}` // send the access token through the 'Authorization' header
                }
              }
          )
          .then(
              () => {
                this.refresh(loader)
              }
          )

      this.refresh(loader)
    },
    async deleteDefect(id) {
      const url = this.defects_api_url + id

      if (confirm('are you sure?')) {
        let loader = this.$loading.show({
          // Optional parameters
          container: this.fullPage ? null : this.$refs.formContainer,
          canCancel: true,
          onCancel: this.onCancel
        })

        axios
            .delete(url, {
              headers: {
                Authorization: `Bearer ${this.token}` // send the access token through the 'Authorization' header
              }
            })
            .then(
                () => {
                  this.refresh(loader)
                }
            )
      }
    },
    async getDefects(token = this.token) {
      //Use Axios to make a call to the API
      let url = this.defects_api_url
      if (this.$props.enableCreate) url = this.defects_api_url + 'building/' + this.$props.url
      const defectsData = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}` // send the access token through the 'Authorization' header
        }
      })

      this.defects = defectsData['data']
      return this.defects.length;
    },
    async refresh(loader, token = this.token) {
      this.getDefects(token)
      this.$forceUpdate()
      loader.hide()
    }
  }
}

</script>
