<template>
  <div class="tenantProfile">
    <section class="section-shaped my-0">
      <div class="shape shape-style-1 shape-default shape-skew">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <h1 class="display-1 text-secondary">
        <i class="fa fa-user-circle" aria-hidden="true"></i> Tenant
      </h1>
      <div class="container">
        <div class="jumbotron">
          <div class="text-left">
            <button
              class="btn btn-danger"
              type="button"
              @click="deleteTenant()"
            >
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                class="bi bi-trash-fill"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z"
                /></svg
              >&#32;
              <span class="text">Delete</span>
            </button>
            &#32;
            <button class="btn btn-primary" type="button" @click="editText()">
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                class="bi bi-pencil-fill"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"
                /></svg
              >&#32;
              <span class="text">Edit</span>
            </button>
            <p></p>
            <h3><i class="fa fa-pencil" aria-hidden="true"></i> Details</h3>
            <form class="container">
              <div class="form-row">
                <div class="form-group col">
                  <label for="tenantReference">Reference:</label>
                  <input
                    type="text"
                    class="form-control"
                    id="tenantReference"
                    ref="tenantReference"
                    :value="tenant.reference"
                    :hidden="!edit"
                  />
                  <p :hidden="edit">{{ tenant.reference }}</p>
                </div>
                <div class="form-group col">
                  <label for="tenantName">First Name:</label>
                  <input
                    type="text"
                    class="form-control"
                    id="tenantName"
                    ref="tenantName"
                    :value="tenant.first_name"
                    :hidden="!edit"
                  />
                  <p :hidden="edit">{{ tenant.first_name }}</p>
                </div>
                <div class="form-group col">
                  <label for="tenantFamilyName">Family Name:</label>
                  <input
                    type="text"
                    class="form-control"
                    id="tenantFamilyName"
                    ref="tenantFamilyName"
                    :value="tenant.family_name"
                    :hidden="!edit"
                  />
                  <p :hidden="edit">{{ tenant.family_name }}</p>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col">
                  <label for="tenantEmail">Email:</label>
                  <input
                    type="email"
                    class="form-control"
                    id="tenantEmail"
                    ref="tenantEmail"
                    :value="tenant.email"
                    :hidden="!edit"
                  />
                  <p :hidden="edit">
                    <a :href="'mailto:' + tenant.email">{{ tenant.email }}</a>
                  </p>
                </div>
                <div class="form-group col">
                  <label for="tenantNumber">Mobile Number:</label>
                  <input
                    type="tel"
                    class="form-control"
                    id="tenantNumber"
                    ref="tenantNumber"
                    :value="tenant.mobile_no"
                    :hidden="!edit"
                  />
                  <p :hidden="edit">
                    <a v-for="number in tenant.mobile_no.split(',')" :key="number" :href="'tel:' + tenant.mobile_no">{{
                      number
                    }}<br></a>
                  </p>
                </div>
                <div class="form-group col"></div>
              </div>
            </form>
            <button
              type="submit"
              class="btn btn-success"
              @click.prevent="editTenant()"
              :hidden="!edit"
            >
              Save Changes
            </button>
            <hr />
            <h3><i class="fa fa-building" aria-hidden="true"></i> Residence</h3>
            <table class="table table-hover">
              <thead class="thead-dark">
                <tr>
                  <th style="width: 25%">Property</th>
                  <th style="width: 15%">Room</th>
                  <th style="width: 10%">Move in date</th>
                  <th style="width: 20%">Move out date</th>
                  <th style="width: 12.5%"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="stay in residence" :key="stay.id">
                  <td>
                    <router-link
                      :to="{
                        name: 'BuildingProfile',
                        query: { buildingId: stay.building_id },
                      }"
                    >
                      <button class="btn btn-primary" type="button">
                        <svg
                          width="1em"
                          height="1em"
                          viewBox="0 0 16 16"
                          class="bi bi-building"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694L1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"
                          />
                          <path
                            d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z"
                          /></svg
                        >&#32;
                        <span class="text"
                          >{{ stay.no_name }}, {{ stay.street }},
                          {{ stay.postcode }}</span
                        >
                      </button>
                    </router-link>
                  </td>
                  <td>{{ stay.name }}</td>
                  <td>
                    {{
                      new Date(stay.move_in_date)
                        .toLocaleDateString("en-GB")
                        .replaceAll("/", "-")
                    }}
                  </td>
                  <td>
                    {{
                      stay.move_out_date
                        ? new Date(stay.move_out_date)
                            .toLocaleDateString("en-GB")
                            .replaceAll("/", "-")
                        : ""
                    }}
                    <button
                      class="btn btn-primary"
                      type="button"
                      @click="moveOut()"
                      v-if="!stay.move_out_date"
                    >
                      <svg
                        width="1em"
                        height="1em"
                        viewBox="0 0 16 16"
                        class="bi bi-house-fill"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M8 3.293l6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293l6-6zm5-.793V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"
                        />
                        <path
                          fill-rule="evenodd"
                          d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"
                        /></svg
                      >&#32;
                      <span class="text">Move Out</span>
                    </button>
                    <p></p>
                    <form
                      class="container"
                      :hidden="!move"
                      v-if="!stay.move_out_date"
                    >
                      <div class="form-row">
                        <div class="col">
                          <label for="moveOutDate">Move Out Date:</label>
                                                <base-input addon-left-icon="ni ni-calendar-grid-58">
                        <flat-picker
                          slot-scope="{ focus, blur }"
                          @on-open="focus"
                          @on-close="blur"
                          :config="{ allowInput: true, altInput: true, altFormat: 'd-m-Y' }"
                          class="form-control datepicker"
                          v-model="moveOutDate"
                          id="moveOutDate"
                          ref="moveOutDate"
                        >
                        </flat-picker>
                      </base-input>
                        </div>
                      </div>
                      <p>{{moveOutDate}}</p>
                      <button
                        type="submit"
                        class="btn btn-success"
                        @click.prevent="editResidence(stay.id)"
                      >
                        Move out
                      </button>
                    </form>
                  </td>
                  <td>
                    <button
                      class="btn btn-danger btn-block"
                      type="button"
                      @click="deleteResidence(stay.id)"
                    >
                      <svg
                        width="1em"
                        height="1em"
                        viewBox="0 0 16 16"
                        class="bi bi-trash-fill"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z"
                        /></svg
                      >&#32;
                      <span class="text">Delete</span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <a
              class="btn btn-success"
              data-toggle="collapse"
              href="#newResidence"
              role="button"
              aria-expanded="false"
              aria-controls="collapse"
            >
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                class="bi bi-plus-circle-fill"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"
                /></svg
              >&#32;
              <span class="text">New Residence</span>
            </a>
            <div class="collapse" id="newResidence">
              <div class="jumbotron jumbotron-fluid">
                <form>
                  <div class="form-row">
                    <div class="col">
                      <label for="property">Building:</label>
                      <select
                        class="form-control"
                        name="property"
                        ref="property"
                        id="property"
                        @change="getRooms()"
                      >
                        <option></option>
                        <option
                          v-for="building in buildings"
                          :key="building.id"
                          :value="building.id"
                          >{{ building.no_name }},{{ building.street }},{{
                            building.postcode
                          }}
                        </option>
                      </select>
                    </div>
                    <div class="col">
                      <label for="rooms">Room:</label>
                      <select
                        class="form-control"
                        name="rooms"
                        ref="rooms"
                        id="rooms"
                      >
                        <option
                          v-for="room in maxOccupants"
                          :key="room.id"
                          :value="room.id"
                        >
                          {{ room.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="col">
                      <label for="moveInDate">Move In Date:</label>
                      <base-input addon-left-icon="ni ni-calendar-grid-58">
                        <flat-picker
                          slot-scope="{ focus, blur }"
                          @on-open="focus"
                          @on-close="blur"
                          :config="{ allowInput: true, altInput: true, altFormat: 'd-m-Y' }"
                          class="form-control datepicker"
                          v-model="moveInDate"
                          id="moveInDate"
                          ref="moveInDate"
                        >
                        </flat-picker>
                      </base-input>
                    </div>
                    <div class="col"></div>
                  </div>
                  <div class="form-row">
                    <div class="col">
                      <p></p>
                      <button
                        type="submit"
                        class="btn btn-success"
                        @click.prevent="createResidence()"
                      >
                        Create
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <hr />
            <Documents
              ref="documents"
              :token="token"
              :url="'owner/tenant/id/' + tenantId"
            />
            <hr />
            <Notes
              ref="notes"
              :url="url + tenantId + '/note'"
              :token="token"
              :id="tenantId"
              id_column="tenant_id"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import app_config from "../../app_config.json";
import Notes from "@/components/Notes.vue";
import Documents from "@/components/Documents.vue";
import getEnv from "@/utils/env";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  name: "LandordProfile",
  components: {
    Notes,
    Documents,
    flatPicker,
  },
  data() {
    return {
      tenant: "",
      token: "",
      edit: false,
      move: false,
      residence: [],
      buildings: [],
      rooms: [],
      url: app_config[getEnv("VUE_APP_ENV")].people_api_url + "tenant/",
      people_api_url: app_config[getEnv("VUE_APP_ENV")].people_api_url,
      buildings_api_url: app_config[getEnv("VUE_APP_ENV")].buildings_api_url,
      rooms_api_url: app_config[getEnv("VUE_APP_ENV")].rooms_api_url,
      moveInDate: "",
      moveOutDate: ""
    };
  },
  props: {
    tenantId: Number,
  },
  created() {
    let loader = this.$loading.show({
      // Optional parameters
      container: this.fullPage ? null : this.$refs.formContainer,
      canCancel: true,
      onCancel: this.onCancel,
    });

    this.callApi(loader);
  },
  computed: {
    maxOccupants: function() {
      return this.rooms.filter(function(r) {
        return r.max_occupancy != r.current_occupancy;
      });
    },
  },
  methods: {
    async editText() {
      if (this.edit) {
        this.edit = false;
      } else {
        this.edit = true;
      }
    },
    async moveOut() {
      if (this.move) {
        this.move = false;
      } else {
        this.move = true;
      }
    },
    async getRooms() {
      if (this.$refs.property.value == "") {
        return;
      }
      const url = this.buildings_api_url + this.$refs.property.value + "/rooms";

      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: true,
        onCancel: this.onCancel,
      });

      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${this.token}`, // send the access token through the 'Authorization' header
          },
        })
        .then((data) => {
          this.rooms = data["data"];
          this.callApi(loader);
        });
    },
    async createResidence() {
      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: true,
        onCancel: this.onCancel,
      });

      const url =
        this.people_api_url + "tenant/" + this.$props.tenantId + "/residence";

      axios
        .post(
          url,
          {
            move_in_date: this.moveInDate,
            room_id: this.$refs.rooms.value,
            tenant: this.$props.tenantId,
          },
          {
            headers: {
              Authorization: `Bearer ${this.token}`, // send the access token through the 'Authorization' header
            },
          }
        )
        .then(() => {
          this.callApi(loader);
        });
    },
    async editResidence(residenceId) {
      const url =
        this.people_api_url +
        "tenant/" +
        this.$props.tenantId +
        "/residence/" +
        residenceId;
      this.$dialog
        .confirm("are you sure?", {
          clicksCount: 3,
          type: "soft",
          okText: "Move Out",
        })
        .then(() => {
          let loader = this.$loading.show({
            // Optional parameters
            container: this.fullPage ? null : this.$refs.formContainer,
            canCancel: true,
            onCancel: this.onCancel,
          });

          axios
            .patch(
              url,
              {
                move_out_date: `'${this.moveOutDate}'`,
              },
              {
                headers: {
                  Authorization: `Bearer ${this.token}`, // send the access token through the 'Authorization' header
                },
              }
            )
            .then(() => {
              this.callApi(loader);
            });
        });
    },
    async deleteResidence(residenceId) {
      const url =
        this.people_api_url +
        "tenant/" +
        this.$props.tenantId +
        "/residence/" +
        residenceId;

      this.$dialog
        .confirm("are you sure?", {
          clicksCount: 3,
          type: "soft",
          okText: "Delete",
        })
        .then(() => {
          let loader = this.$loading.show({
            // Optional parameters
            container: this.fullPage ? null : this.$refs.formContainer,
            canCancel: true,
            onCancel: this.onCancel,
          });

          axios
            .delete(url, {
              headers: {
                Authorization: `Bearer ${this.token}`, // send the access token through the 'Authorization' header
              },
            })
            .then(() => {
              this.callApi(loader);
            });
        });
    },
    async deleteTenant() {
      const url = this.people_api_url + "tenant/" + this.$props.tenantId;

      this.$dialog
        .confirm("are you sure?", {
          verification: "confirm",
          type: "hard",
          okText: "Delete",
        })
        .then(() => {
          let loader = this.$loading.show({
            // Optional parameters
            container: this.fullPage ? null : this.$refs.formContainer,
            canCancel: true,
            onCancel: this.onCancel,
          });

          axios
            .delete(url, {
              headers: {
                Authorization: `Bearer ${this.token}`, // send the access token through the 'Authorization' header
              },
            })
            .then(() => {
              loader.hide();
              this.$router.push("tenants");
            });
        });
    },
    async editTenant() {
      const url = this.people_api_url + "tenant/" + this.$props.tenantId;

      this.$dialog
        .confirm("are you sure?", {
          clicksCount: 3,
          type: "soft",
          okText: "Edit",
        })
        .then(() => {
          let loader = this.$loading.show({
            // Optional parameters
            container: this.fullPage ? null : this.$refs.formContainer,
            canCancel: true,
            onCancel: this.onCancel,
          });

          axios
            .patch(
              url,
              {
                first_name: `'${this.$refs["tenantName"].value.trim()}'`,
                family_name: `'${this.$refs["tenantFamilyName"].value.trim()}'`,
                email: `'${this.$refs["tenantEmail"].value.trim()}'`,
                mobile_no: `'${this.$refs["tenantNumber"].value.trim()}'`,
                reference: `'${this.$refs["tenantReference"].value.trim()}'`,
              },
              {
                headers: {
                  Authorization: `Bearer ${this.token}`, // send the access token through the 'Authorization' header
                },
              }
            )
            .then(() => {
              this.callApi(loader);
            });
        });
    },
    async callApi(loader) {
      // Get the access token from the auth wrapper
      this.token = await this.$auth.getTokenSilently();
      var url = this.people_api_url + "tenant/" + this.$props.tenantId;

      // Use Axios to make a call to the API
      const tenantData = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${this.token}`, // send the access token through the 'Authorization' header
        },
      });

      this.tenant = tenantData["data"][0];

      url =
        this.people_api_url + "tenant/" + this.$props.tenantId + "/residence";

      //Use Axios to make a call to the API
      const residenceData = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${this.token}`, // send the access token through the 'Authorization' header
        },
      });

      this.residence = residenceData["data"];

      url = this.buildings_api_url;

      //Use Axios to make a call to the API
      const buildingsData = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${this.token}`, // send the access token through the 'Authorization' header
        },
      });

      this.buildings = buildingsData["data"];

      this.$refs.notes.getNotes();
      this.$refs.documents.getDocuments();
      this.edit = false;
      this.$forceUpdate();
      loader.hide();
    },
  },
};
</script>
