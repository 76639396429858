<template>
  <div class="buildings">
    <section class="section-shaped my-0">
      <div class="shape shape-style-1 shape-default shape-skew">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <h1 class="display-1 text-secondary">
        <i class="fa fa-building" aria-hidden="true"></i> Properties
      </h1>
      <div class="container">
        <div class="jumbotron">
          <div class="row">
            <div class="col text-left">
              <a
                class="btn btn-success"
                data-toggle="collapse"
                href="#collapse"
                role="button"
                aria-expanded="false"
                aria-controls="collapse"
              >
                <i class="fa fa-plus" aria-hidden="true"></i> New Property
              </a>
              &#32;
              <a
                class="btn btn-primary"
                data-toggle="collapse"
                href="#search"
                role="button"
                aria-expanded="false"
                aria-controls="collapse"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-search"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"
                  />
                </svg>
                &#32;
                <span class="text">Search</span>
              </a>
            </div>
            <div class="col-sm text-right">
              <button
                type="button"
                class="btn btn-primary"
                @click="downloadCSV"
              >
                <span class="text">Download</span>&#32;
                <svg
                  width="1em"
                  height="1em"
                  viewBox="0 0 16 16"
                  class="bi bi-file-earmark-arrow-down-fill"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2 2a2 2 0 0 1 2-2h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm7 2l.5-2.5 3 3L10 5a1 1 0 0 1-1-1zm-.5 3.5a.5.5 0 0 0-1 0v3.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 2a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 11.293V7.5z"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div class="row">
            <p></p>
          </div>
          <div class="accordion-group" id="formGroup">
            <div class="row">
              <div class="col text-left">
                <div class="collapse" id="collapse" data-parent="#formGroup">
                  <div class="jumbotron jumbotron-fluid">
                    <form class="container">
                      <div class="form-row">
                        <div class="form-group col">
                          <label for="map">Auto Complete:</label>
                          <vue-google-autocomplete
                            id="map"
                            classname="form-control"
                            placeholder="Start typing"
                            v-on:placechanged="getAddressData"
                            :country="['gb']"
                          >
                          </vue-google-autocomplete>
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="form-group col">
                          <label for="reference">Reference: </label>
                          <input
                            type="text"
                            class="form-control"
                            id="reference"
                            ref="reference"
                            placeholder="Reference"
                          />
                        </div>
                        <div class="form-group col">
                          <label for="startDate">Start Date: </label>
                          <base-input addon-left-icon="ni ni-calendar-grid-58">
                            <flat-picker
                              slot-scope="{ focus, blur }"
                              @on-open="focus"
                              @on-close="blur"
                              :config="{
                                allowInput: true,
                                altInput: true,
                                altFormat: 'd-m-Y',
                              }"
                              class="form-control datepicker"
                              v-model="startDate"
                              id="startDate"
                              ref="startDate"
                            >
                            </flat-picker>
                          </base-input>
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="form-group col">
                          <label for="buildingNumberName"
                            >Property Number/Name:
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            id="buildingNumberName"
                            ref="buildingNumberName"
                            placeholder="Building Number/Name"
                            :value="address['street_number']"
                          />
                        </div>
                        <div class="form-group col">
                          <label for="street">Street: </label>
                          <input
                            type="text"
                            class="form-control"
                            id="street"
                            ref="street"
                            placeholder="Street"
                            :value="address['route']"
                          />
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="form-group col">
                          <label for="borough">Borough: </label>
                          <input
                            type="text"
                            class="form-control"
                            id="borough"
                            ref="borough"
                            placeholder="Borough"
                          />
                        </div>
                        <div class="form-group col">
                          <label for="postcode">Postcode: </label>
                          <input
                            type="text"
                            class="form-control"
                            id="postcode"
                            ref="postcode"
                            placeholder="Postcode"
                            :value="address['postal_code']"
                          />
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="form-group col">
                          <label for="postcode">Max Occupancy: </label>
                          <input
                            type="text"
                            class="form-control"
                            id="maxOccupancy"
                            ref="maxOccupancy"
                            placeholder="Max Occupancy"
                            value="0"
                          />
                        </div>
                        <div class="form-group col"></div>
                      </div>
                      <button
                        type="submit"
                        class="btn btn-success"
                        @click.prevent="createBuilding"
                      >
                        Create
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col text-left">
                <div class="collapse" id="search" data-parent="#formGroup">
                  <div class="jumbotron jumbotron-fluid">
                    <form class="container">
                      <div class="form-row">
                        <div class="form-group col">
                          <label for="map-search">Auto Complete:</label>
                          <vue-google-autocomplete
                            id="map-search"
                            classname="form-control"
                            placeholder="Start typing"
                            v-on:placechanged="getSearchAddressData"
                            :country="['gb']"
                          >
                          </vue-google-autocomplete>
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="form-group col">
                          <label for="searchReference">Reference: </label>
                          <input
                            type="text"
                            class="form-control"
                            id="searchReference"
                            ref="searchReference"
                            placeholder="Reference"
                          />
                        </div>
                        <div class="form-group col">
                          <label for="searchStartDate">Start Date: </label>
                          <base-input addon-left-icon="ni ni-calendar-grid-58">
                            <flat-picker
                              slot-scope="{ focus, blur }"
                              @on-open="focus"
                              @on-close="blur"
                              :config="{
                                allowInput: true,
                                altInput: true,
                                altFormat: 'd-m-Y',
                              }"
                              class="form-control datepicker"
                              v-model="searchStartDate"
                              id="searchStartDate"
                              ref="searchStartDate"
                            >
                            </flat-picker>
                          </base-input>
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="form-group col">
                          <label for="searchNumberName"
                            >Property Number/Name:
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            id="searchNumberName"
                            ref="searchNumberName"
                            placeholder="Building Number/Name"
                            :value="addressSearch['street_number']"
                          />
                        </div>
                        <div class="form-group col">
                          <label for="searchStreet">Street: </label>
                          <input
                            type="text"
                            class="form-control"
                            id="searchStreet"
                            ref="searchStreet"
                            placeholder="Street"
                            :value="addressSearch['route']"
                          />
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="form-group col">
                          <label for="borough">Borough: </label>
                          <input
                            type="text"
                            class="form-control"
                            id="searchBorough"
                            ref="searchBorough"
                            placeholder="Borough"
                          />
                        </div>
                        <div class="form-group col">
                          <label for="postcode">Postcode: </label>
                          <input
                            type="text"
                            class="form-control"
                            id="searchPostcode"
                            ref="searchPostcode"
                            placeholder="Postcode"
                            :value="addressSearch['postal_code']"
                          />
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="form-group col"></div>
                        <div class="form-group col">
                          <label for="archived">Show Archived: </label>
                          <base-checkbox
                            id="archived"
                            v-model="archived"
                          ></base-checkbox>
                        </div>
                        
                      </div>
                      <button
                        type="submit"
                        class="btn btn-success"
                        @click.prevent="search"
                      >
                        Search
                      </button>
                      &#32;
                      <button
                        type="submit"
                        class="btn btn-primary"
                        @click.prevent="clearSearch"
                      >
                        Clear Filters
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <p></p>
          </div>
          <div class="row">
            <p></p>
          </div>
          <div class="row">
            <table class="table table-hover">
              <thead class="thead-dark">
                <tr>
                  <th style="width: 10%">Reference</th>
                  <th style="width: 10%">Property Number/Name</th>
                  <th style="width: 20%">Street</th>
                  <th style="width: 20%">Borough</th>
                  <th style="width: 10%">Postcode</th>
                  <th style="width: 10%"></th>
                </tr>
              </thead>
              <tbody v-for="building in buildings" :key="building.id">
                <tr>
                  <td>{{ building.reference }}</td>
                  <td>{{ building.no_name }}</td>
                  <td>{{ building.street }}</td>
                  <td>{{ building.borough }}</td>
                  <td>{{ building.postcode }}</td>
                  <td>
                    <router-link
                      class="nav-link"
                      :to="{
                        name: 'BuildingProfile',
                        query: { buildingId: building.id },
                      }"
                    >
                      <button
                        class="btn btn-primary"
                        type="button"
                        name="button"
                      >
                        <i class="bi bi-building"></i> More
                      </button>
                    </router-link>
                    <div
                      v-if="building.archived"
                      class="btn-success btn btn-sm"
                    >
                      Archived
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { ExportToCsv } from "export-to-csv";
import app_config from "../../app_config.json";
import getEnv from "@/utils/env";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import VueGoogleAutocomplete from "vue-google-autocomplete";

export default {
  name: "external-api",
  components: {
    flatPicker,
    VueGoogleAutocomplete,
  },
  data() {
    return {
      buildings: "",
      token: "",
      buildings_api_url: app_config[getEnv("VUE_APP_ENV")].buildings_api_url,
      startDate: "",
      searchStartDate: "",
      address: "",
      addressSearch: "",
      archived: false,
    };
  },
  created() {
    let loader = this.$loading.show({
      // Optional parameters
      container: this.fullPage ? null : this.$refs.formContainer,
      canCancel: true,
      onCancel: this.onCancel,
    });

    this.callApi(loader);
  },
  methods: {
    async createBuilding() {
      const url = this.buildings_api_url;

      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: true,
        onCancel: this.onCancel,
      });

      axios
        .post(
          url,
          {
            reference: this.$refs.reference.value.trim(),
            no_name: this.$refs.buildingNumberName.value.trim(),
            street: this.$refs.street.value.trim(),
            borough: this.$refs.borough.value.trim(),
            postcode: this.$refs.postcode.value.trim().toUpperCase(),
            start_date: this.startDate,
            max_occupancy: this.$refs.maxOccupancy.value,
          },
          {
            headers: {
              Authorization: `Bearer ${this.token}`, // send the access token through the 'Authorization' header
            },
          }
        )
        .then(() => {
          this.$refs.reference.value = "";
          this.$refs.buildingNumberName.value = "";
          this.$refs.street.value = "";
          this.$refs.borough.value = "";
          this.$refs.postcode.value = "";
          this.startDate = "";
          this.$refs.maxOccupancy = 0;
          this.callApi(loader);
        });
    },
    downloadCSV() {
      const options = {
        fieldSeparator: ",",
        quoteStrings: '"',
        decimalSeparator: ".",
        showLabels: true,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        filename: "buildings",
        // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
      };

      const csvExporter = new ExportToCsv(options);

      csvExporter.generateCsv(this.buildings);
    },
    async clearSearch() {
      this.$refs.searchReference.value = "";
      this.$refs.searchNumberName.value = "";
      this.$refs.searchStreet.value = "";
      this.$refs.searchBorough.value = "";
      this.$refs.searchPostcode.value = "";
      this.searchStartDate = "";

      this.search();
    },
    async search() {
      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: true,
        onCancel: this.onCancel,
      });

      this.callApi(loader);
    },
    async callApi(loader) {
      // Get the access token from the auth wrapper
      this.token = await this.$auth.getTokenSilently();

      let params = {};

      if (!this.archived) {
        params["archived"] = this.archived;
      }

      if (this.$refs.searchReference.value.trim().length > 0) {
        params["reference"] = this.$refs.searchReference.value.trim();
      }

      if (this.$refs.searchNumberName.value.trim().length > 0) {
        params["no_name"] = this.$refs.searchNumberName.value.trim();
      }

      if (this.$refs.searchStreet.value.trim().length > 0) {
        params["street"] = this.$refs.searchStreet.value.trim();
      }

      if (this.$refs.searchBorough.value.trim().length > 0) {
        params["borough"] = this.$refs.searchBorough.value.trim();
      }

      if (this.$refs.searchPostcode.value.trim().length > 0) {
        params["postcode"] = this.$refs.searchPostcode.value.trim();
      }

      if (this.$refs.searchStartDate.value.length > 0) {
        params["start_date"] = this.searchStartDate;
      }

      // Use Axios to make a call to the API
      const { data } = await axios.get(this.buildings_api_url, {
        headers: {
          Authorization: `Bearer ${this.token}`, // send the access token through the 'Authorization' header
        },
        params: params,
      });

      this.buildings = data;
      this.$forceUpdate();
      loader.hide();
    },
    getAddressData: function(addressData, placeResultData, id) {
      this.address = addressData;
    },
    getSearchAddressData: function(addressData, placeResultData, id) {
      this.addressSearch = addressData;
    },
  },
};
</script>
