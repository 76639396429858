<template>
  <div class="buildingProfile">
    <section class="section-shaped my-0">
      <div class="shape shape-style-1 shape-default shape-skew">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <h1 class="display-1 text-secondary">
        <i class="fa fa-building" aria-hidden="true"></i> Property <div v-if="building.archived" class="btn-success btn">
              Archived
            </div>
      </h1>
      <div class="container">
        <div class="jumbotron">
          <div class="text-left">
            <button
              class="btn btn-danger"
              type="button"
              @click="deleteBuilding()"
            >
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                class="bi bi-trash-fill"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z"
                /></svg
              >&#32;
              <span class="text">Delete</span>
            </button>
            &#32;
            <button class="btn btn-primary" type="button" @click="editText()">
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                class="bi bi-pencil-fill"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"
                /></svg
              >&#32;
              <span class="text">Edit</span>
            </button>
            <button
          class="btn btn-warning"
          type="button"
          @click="archiveBuilding()"
          v-if="!building.archived"
        >
          <i class="fa fa-archive"></i>
          <span class="text">Archive</span>
        </button>
            <p></p>
            <h3><i class="fa fa-pencil" aria-hidden="true"></i> Details</h3>
            <form class="container">
              <div class="form-row">
                <div class="form-group col">
                  <label for="buildingReference">Reference: </label>
                  <input
                    type="text"
                    class="form-control"
                    id="buildingReference"
                    ref="buildingReference"
                    :value="building.reference"
                    :hidden="!edit"
                  />
                  <p :hidden="edit">{{ building.reference }}</p>
                </div>
                <div class="form-group col">
                  <label for="buildingReference">Max Occupancy: </label>
                  <input
                    type="number"
                    class="form-control"
                    id="buildingMaxOccupancy"
                    ref="buildingMaxOccupancy"
                    :value="building.max_occupancy"
                    :hidden="!edit"
                  />
                  <p :hidden="edit">{{ building.max_occupancy }}</p>
                </div>
                <div class="form-group col">
                  <label>Current Occupancy: </label>
                  <p>{{ current_occupancy }}</p>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col">
                  <label for="buildingNumberName">Property Number/Name: </label>
                  <input
                    type="text"
                    class="form-control"
                    id="buildingNumberName"
                    ref="buildingNumberName"
                    :value="building.no_name"
                    :hidden="!edit"
                  />
                  <p :hidden="edit">{{ building.no_name }}</p>
                </div>
                <div class="form-group col">
                  <label for="street">Street: </label>
                  <input
                    type="text"
                    class="form-control"
                    id="street"
                    ref="street"
                    :value="building.street"
                    :hidden="!edit"
                  />
                  <p :hidden="edit">{{ building.street }}</p>
                </div>
                <div class="form-group col">
                  <label for="borough">Borough: </label>
                  <input
                    type="text"
                    class="form-control"
                    id="borough"
                    ref="borough"
                    :value="building.borough"
                    :hidden="!edit"
                  />
                  <p :hidden="edit">{{ building.borough }}</p>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col">
                  <label for="postcode">Postcode: </label>
                  <input
                    type="text"
                    class="form-control"
                    id="postcode"
                    ref="postcode"
                    :value="building.postcode"
                    :hidden="!edit"
                  />
                  <p :hidden="edit">{{ building.postcode }}</p>
                </div>
                <div class="form-group col">
                  <label>Landlord: </label>
                  <p v-if="building.landlord_id != null">
                    <router-link
                      :to="{
                        name: 'LandlordProfile',
                        query: { landlordId: building.landlord_id },
                      }"
                    >
                      <button
                        class="btn btn-primary"
                        type="button"
                        name="landlord"
                      >
                        <svg
                          width="1em"
                          height="1em"
                          viewBox="0 0 16 16"
                          class="bi bi-person-lines-fill"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm7 1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm2 9a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"
                          />
                        </svg>
                        {{ landlord.first_name }}, {{ landlord.family_name }}
                      </button>
                    </router-link>
                  </p>
                  <p v-else class="text-danger">None Assigned</p>
                </div>
                <div class="form-group col">
                  <label>Start Date: </label>
                  <div :hidden="!edit">
                    <base-input addon-left-icon="ni ni-calendar-grid-58">
                      <flat-picker
                        slot-scope="{ focus, blur }"
                        @on-open="focus"
                        @on-close="blur"
                        :config="{
                          allowInput: true,
                          altInput: true,
                          altFormat: 'd-m-Y',
                        }"
                        class="form-control datepicker"
                        v-model="startDate"
                        id="startDate"
                        ref="startDate"
                      >
                      </flat-picker>
                    </base-input>
                  </div>
                  <p :hidden="edit">
                    {{
                      building.start_date
                        ? new Date(building.start_date)
                            .toLocaleDateString("en-GB")
                            .replaceAll("/", "-")
                        : ""
                    }}
                  </p>
                </div>
              </div>
            </form>
            <button
              type="submit"
              class="btn btn-success"
              @click.prevent="editBuilding()"
              :hidden="!edit"
            >
              Save Changes
            </button>
            <hr />
            <tabs>
              <tab-pane :title="'Rooms (' + noRooms + ')'" active>
                <h3><i class="fa fa-bed" aria-hidden="true"></i> Rooms</h3>
                <table class="table table-hover">
                  <thead class="thead-dark">
                    <tr>
                      <th style="width: 20%">Name</th>
                      <th style="width: 20%">Dimensions</th>
                      <th style="width: 10%">Max Occupancy</th>
                      <th style="width: 30%">Occupants</th>
                      <th style="width: 20%"></th>
                    </tr>
                  </thead>
                  <tbody v-for="room in rooms" :key="room.id">
                    <tr>
                      <td>{{ room.name }}</td>
                      <td>{{ room.dimensions }}</td>
                      <td>{{ room.max_occupancy }}</td>
                      <td>
                        <div v-if="room.occupants.length > 0">
                          <p
                            v-for="occupant in room.occupants"
                            :key="occupant.id"
                          >
                            <router-link
                              :to="{
                                name: 'TenantProfile',
                                query: { tenantId: occupant.id },
                              }"
                            >
                              <button
                                class="btn btn-primary"
                                type="button"
                                name="button"
                              >
                                <svg
                                  width="1em"
                                  height="1em"
                                  viewBox="0 0 16 16"
                                  class="bi bi-person-lines-fill"
                                  fill="currentColor"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm7 1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm2 9a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"
                                  />
                                </svg>
                                {{ occupant.name }}
                              </button>
                            </router-link>
                          </p>
                        </div>
                        <div v-else>
                          <p>None</p>
                        </div>
                      </td>
                      <td>
                        <a
                          class="btn btn-primary"
                          data-toggle="collapse"
                          :href="'#collapse-' + room.id"
                          role="button"
                          aria-expanded="false"
                          :aria-controls="'collapse-' + room.id"
                          v-b-tooltip.hover.top
                          title="Edit Room"
                        >
                          <svg
                            width="1em"
                            height="1em"
                            viewBox="0 0 16 16"
                            class="bi bi-pencil-fill"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"
                            />
                          </svg>
                        </a>
                        <button
                          class="btn btn-danger"
                          type="button"
                          @click="deleteRoom(room.id)"
                          v-b-tooltip.hover.top
                          title="Delete Room"
                        >
                          <svg
                            width="1em"
                            height="1em"
                            viewBox="0 0 16 16"
                            class="bi bi-trash-fill"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z"
                            />
                          </svg>
                        </button>
                      </td>
                    </tr>
                    <tr class="collapse" :id="'collapse-' + room.id">
                      <td colspan="5">
                        <div class="jumbotron jumbotron-fluid">
                          <form class="container">
                            <div class="form-row">
                              <div class="form-group col">
                                <label :for="'roomName-' + room.id"
                                  >Room Name:
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  :ref="'roomName-' + room.id"
                                  :value="room.name"
                                />
                              </div>
                              <div class="form-group col">
                                <label :for="'roomName-' + room.id"
                                  >Dimensions:
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  :ref="'roomDimensions-' + room.id"
                                  :value="room.dimensions"
                                />
                              </div>
                              <div class="form-group col">
                                <label :for="'roomName-' + room.id"
                                  >Max Occupancy:
                                </label>
                                <input
                                  type="number"
                                  class="form-control"
                                  :ref="'roomMaxOccupancy-' + room.id"
                                  :value="room.max_occupancy"
                                />
                              </div>
                            </div>
                            <button
                              type="submit"
                              class="btn btn-success"
                              @click.prevent="editRoom(room.id)"
                            >
                              Save
                            </button>
                          </form>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <a
                  class="btn btn-success"
                  data-toggle="collapse"
                  href="#newRoom"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapse"
                >
                  <svg
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    class="bi bi-plus-circle-fill"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"
                    /></svg
                  >&#32;
                  <span class="text">Room</span>
                </a>
                <div class="collapse" id="newRoom">
                  <div class="jumbotron jumbotron-fluid">
                    <form class="container">
                      <div class="form-row">
                        <div class="form-group col">
                          <label for="roomName">Name: </label>
                          <input
                            type="text"
                            class="form-control"
                            id="roomName"
                            ref="roomName"
                            placeholder="Room Name"
                          />
                        </div>
                        <div class="form-group col">
                          <label for="roomName">Dimensions: </label>
                          <input
                            type="text"
                            class="form-control"
                            id="roomDimension"
                            ref="roomDimension"
                            placeholder="Room Dimensions"
                          />
                        </div>
                        <div class="form-group col">
                          <label for="roomName">Maximum Occupancy: </label>
                          <input
                            type="number"
                            class="form-control"
                            id="roomMaxOccupancy"
                            ref="roomMaxOccupancy"
                            placeholder="Room Maximum Occupancy"
                          />
                        </div>
                      </div>
                      <button
                        type="submit"
                        class="btn btn-success"
                        @click.prevent="createRoom"
                      >
                        Create
                      </button>
                    </form>
                  </div>
                </div>
              </tab-pane>
              <tab-pane :title="'Defects (' + noDefects + ')'">
                <Defects ref="defects" :token="token" :url="buildingId" />
              </tab-pane>
              <tab-pane :title="'Documents (' + noDocuments + ')'">
                <Documents
                  ref="documents"
                  :token="token"
                  :url="'owner/building/id/' + buildingId"
                />
              </tab-pane>
              <tab-pane :title="'Incidents (' + noIncidents + ')'">
                <h3>
                  <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                  Incidents
                </h3>
                <table class="table table-hover" style="table-layout: fixed; word-wrap: break-word;">
                  <thead class="thead-dark">
                    <tr>
                      <th style="width: 30%"></th>
                      <th style="width: 50%">Description</th>
                      <th style="width: 20%"></th>
                    </tr>
                  </thead>
                  <tbody v-for="incident in incidents" :key="incident.id">
                    <tr>
                      <td>
                        <b>ID</b>: {{ incident.id }} <br />
                        <b>Author</b>: {{ incident.author }} <br />
                        <b>Creation Date</b>:
                        {{
                          new Date(incident.creation_date)
                            .toLocaleDateString("en-GB")
                            .replaceAll("/", "-") +
                            " " +
                            new Date(incident.creation_date)
                              .toLocaleTimeString("en-GB")
                              .slice(0, -3)
                        }}
                        <b>Incident Date</b>:
                        {{
                          incident.incident_date
                            ? new Date(incident.incident_date)
                                .toLocaleDateString("en-GB")
                                .replaceAll("/", "-")
                            : ""
                        }}
                      </td>
                      <td>
                        {{ incident.description }}<br /><br />
                        <div v-for="file in incident.files" :key="file">
                          <router-link
                            :to="{
                              name: 'Documents',
                              query: { file: file },
                            }"
                          >
                            <button class="btn btn-default" type="button">
                              {{ file }}
                            </button>
                          </router-link>
                          <p></p>
                        </div>
                      </td>
                      <td>
                        <a
                          class="btn btn-primary"
                          data-toggle="collapse"
                          :href="'#incident-' + incident.id"
                          role="button"
                          aria-expanded="false"
                          :aria-controls="'incident-' + incident.id"
                          v-b-tooltip.hover.top
                          title="Edit Incident"
                        >
                          <svg
                            width="1em"
                            height="1em"
                            viewBox="0 0 16 16"
                            class="bi bi-pencil-fill"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"
                            />
                          </svg>
                        </a>
                        <b-button
                          v-b-tooltip.hover.top
                          title="Delete Incident"
                          id="tooltip-target-1"
                          class="btn btn-danger"
                          @click="deleteIncident(incident.id)"
                        >
                          <svg
                            width="1em"
                            height="1em"
                            viewBox="0 0 16 16"
                            class="bi bi-trash-fill"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z"
                            />
                          </svg>
                        </b-button>
                      </td>
                    </tr>
                    <tr class="collapse" :id="'incident-' + incident.id">
                      <td colspan="5">
                        <div class="jumbotron jumbotron-fluid">
                          <form class="container">
                            <div class="form-row">
                              <div class="form-group col">
                                <label :for="'incident-' + incident.id"
                                  >Description:
                                </label>
                                <textarea
                                  class="form-control"
                                  :ref="'incidentDescription-' + incident.id"
                                  :value="incident.description"
                                  id="description"
                                  rows="8"
                                  cols="80"
                                ></textarea>
                              </div>
                            </div>
                            <div class="form-row">
                              <div class="form-group col">
                                <label for="incidentDate"
                                  >Incident Date:
                                </label>
                                <base-input
                                  addon-left-icon="ni ni-calendar-grid-58"
                                >
                                  <flat-picker
                                    slot-scope="{ focus, blur }"
                                    @on-open="focus"
                                    @on-close="blur"
                                    :config="{
                                      allowInput: true,
                                      altInput: true,
                                      altFormat: 'd-m-Y',
                                    }"
                                    class="form-control datepicker"
                                    v-model="incident.incident_date"
                                    id="incidentDate"
                                    :ref="'incidentDate-' + incident.id"
                                  >
                                  </flat-picker>
                                </base-input>
                              </div>
                            </div>
                            <button
                              type="submit"
                              class="btn btn-success"
                              @click.prevent="editIncident(incident.id)"
                            >
                              Save
                            </button>
                          </form>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <a
                  class="btn btn-success"
                  data-toggle="collapse"
                  href="#newIncident"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapse"
                >
                  <svg
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    class="bi bi-plus-circle-fill"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"
                    /></svg
                  >&#32;
                  <span class="text">Incident</span>
                </a>
                <div class="collapse" id="newIncident">
                  <div class="jumbotron jumbotron-fluid">
                    <form class="container">
                      <div class="form-row">
                        <div class="form-group col">
                          <label for="incidentDate">Incident Date: </label>
                          <base-input addon-left-icon="ni ni-calendar-grid-58">
                            <flat-picker
                              slot-scope="{ focus, blur }"
                              @on-open="focus"
                              @on-close="blur"
                              :config="{
                                allowInput: true,
                                altInput: true,
                                altFormat: 'd-m-Y',
                              }"
                              class="form-control datepicker"
                              v-model="incidentDate"
                              id="incidentDate"
                              ref="incidentDate"
                            >
                            </flat-picker>
                          </base-input>
                        </div>
                        <div class="form-group col">
                          <label for="incidentFile">File : </label>
                          <b-form-file
                            v-model="incidentFile"
                            ref="incidentFile"
                            id="incidentFile"
                          ></b-form-file>
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="form-group col">
                          <label for="description">Description: </label>
                          <textarea
                            class="form-control"
                            ref="description"
                            id="description"
                            rows="8"
                            cols="80"
                          ></textarea>
                        </div>
                      </div>
                      <button
                        type="submit"
                        class="btn btn-success"
                        @click.prevent="createIncident"
                      >
                        Create
                      </button>
                    </form>
                  </div>
                </div>
              </tab-pane>
              <tab-pane title="Utilities">
                <Utilities
                  ref="utilities"
                  :gas="building.gas_supplier"
                  :electric="building.electric_supplier"
                  :water="building.water_supplier"
                  :url="buildings_api_url + buildingId"
                  :token="token"
                  :callApi="callApi"
                  :building_id="buildingId"
                />
              </tab-pane>
              <tab-pane title="Inventory">
                <Inventory
                  ref="inventory"
                  :token="token"
                  :building_id="buildingId"
                  :callApi="callApi"
                />
              </tab-pane>
              <tab-pane :title="'Notes (' + noNotes + ')'">
                <Notes
                  ref="notes"
                  :url="buildings_api_url + buildingId + '/note'"
                  :token="token"
                  :id="buildingId"
                  id_column="building_id"
                />
              </tab-pane>
            </tabs>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import app_config from "../../app_config.json";
import Notes from "@/components/Notes.vue";
import Utilities from "@/components/Utilities.vue";
import Documents from "@/components/Documents";
import Defects from "@/components/Defects";
import getEnv from "@/utils/env";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import Tabs from "@/components/Tabs/Tabs.vue";
import TabPane from "@/components/Tabs/TabPane.vue";
import { VBTooltip } from "bootstrap-vue/esm/directives/tooltip/tooltip";
import { VBPopover } from "bootstrap-vue/esm/directives/popover/popover";
import "bootstrap-vue/dist/bootstrap-vue.min.css";
import Inventory from '../components/Inventory.vue';

export default {
  name: "BuildingProfile",
  directives: {
    BTooltip: VBTooltip,
    BPopover: VBPopover,
  },
  data() {
    return {
      file_api_url: app_config[getEnv("VUE_APP_ENV")].file_api_url,
      buildings_api_url: app_config[getEnv("VUE_APP_ENV")].buildings_api_url,
      incidents_api_url: app_config[getEnv("VUE_APP_ENV")].incidents_api_url,
      rooms_api_url: app_config[getEnv("VUE_APP_ENV")].rooms_api_url,
      landlord_api_url:
        app_config[getEnv("VUE_APP_ENV")].people_api_url + "landlord/",
      landlord: "",
      building: "",
      rooms: "",
      edit: false,
      token: "",
      startDate: "",
      current_occupancy: 0,
      noNotes: 0,
      noDocuments: 0,
      noDefects: 0,
      noRooms: 0,
      noIncidents: 0,
      incidents: [],
      incidentDate: "",
      incidentFile: "",
    };
  },
  props: {
    buildingId: Number,
  },
  components: {
    Notes,
    Documents,
    Defects,
    flatPicker,
    Tabs,
    TabPane,
    Utilities,
    Inventory
  },
  created() {
    let loader = this.$loading.show({
      // Optional parameters
      container: this.fullPage ? null : this.$refs.formContainer,
      canCancel: true,
      onCancel: this.onCancel,
    });

    this.callApi(loader);
  },
  methods: {
    async editText() {
      if (this.edit) {
        this.edit = false;
      } else {
        this.edit = true;
      }
    },
    async editBuilding() {
      const url = this.buildings_api_url + this.$props.buildingId;

      this.$dialog
        .confirm("are you sure?", {
          clicksCount: 3,
          type: "soft",
          okText: "Edit",
        })
        .then(() => {
          let loader = this.$loading.show({
            // Optional parameters
            container: this.fullPage ? null : this.$refs.formContainer,
            canCancel: true,
            onCancel: this.onCancel,
          });

          let payload = {
            no_name: `'${this.$refs["buildingNumberName"].value.trim()}'`,
            street: `'${this.$refs["street"].value.trim()}'`,
            postcode: `'${this.$refs["postcode"].value.trim()}'`,
            reference: `'${this.$refs["buildingReference"].value.trim()}'`,
            borough: `'${this.$refs["borough"].value.trim()}'`,
            max_occupancy: `'${this.$refs[
              "buildingMaxOccupancy"
            ].value.trim()}'`,
          };

          this.$refs["startDate"].value
            ? (payload["start_date"] = `'${this.$refs["startDate"].value}'`)
            : null;

          axios
            .patch(url, payload, {
              headers: {
                Authorization: `Bearer ${this.token}`, // send the access token through the 'Authorization' header
              },
            })
            .then(() => {
              this.callApi(loader);
            });
        });
    },
    async deleteBuilding() {
      const url = this.buildings_api_url + this.$props.buildingId;

      this.$dialog
        .confirm("are you sure?", {
          verification: "confirm",
          type: "hard",
          okText: "Delete",
        })
        .then(() => {
          let loader = this.$loading.show({
            // Optional parameters
            container: this.fullPage ? null : this.$refs.formContainer,
            canCancel: true,
            onCancel: this.onCancel,
          });

          axios
            .delete(url, {
              headers: {
                Authorization: `Bearer ${this.token}`, // send the access token through the 'Authorization' header
              },
            })
            .then(() => {
              loader.hide();
              this.$router.push("buildings");
            });
        });
    },
    async archiveBuilding() {
      const url = this.buildings_api_url + this.$props.buildingId + '/archive/';

      this.$dialog
        .confirm("are you sure?", {
          verification: "confirm",
          type: "hard",
          okText: "Archive",
        })
        .then(() => {
          let loader = this.$loading.show({
            // Optional parameters
            container: this.fullPage ? null : this.$refs.formContainer,
            canCancel: true,
            onCancel: this.onCancel,
          });

          axios
            .patch(url,{}, {
              headers: {
                Authorization: `Bearer ${this.token}`, // send the access token through the 'Authorization' header
              },
            })
            .then(() => {
              loader.hide();
              this.$router.push("buildings");
            });
        });
    },
    async createRoom() {
      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: true,
        onCancel: this.onCancel,
      });

      const url = this.rooms_api_url;

      axios
        .post(
          url,
          {
            name: this.$refs.roomName.value.trim(),
            dimensions: this.$refs.roomDimension.value.trim(),
            max_occupancy: this.$refs.roomMaxOccupancy.value,
            building_id: this.$props.buildingId,
          },
          {
            headers: {
              Authorization: `Bearer ${this.token}`, // send the access token through the 'Authorization' header
            },
          }
        )
        .then(() => {
          this.$refs.roomName.value = "";
          this.$refs.roomDimension.value = "";
          this.$refs.roomMaxOccupancy.value = "";
          this.callApi(loader);
        });
    },
    async createIncident() {
      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: true,
        onCancel: this.onCancel,
      });

      let payload = {
            building_id: this.$props.buildingId,
            author: this.$auth.user.name,
            description: this.$refs.description.value,
            incident_date: this.incidentDate,
            files: '{}'
          }

      const url = this.incidents_api_url + "building/" + this.$props.buildingId;

      if (this.incidentFile){
        this.uploadDocument()
        payload['files'] = '{"'+this.incidentFile.name+'"}'
      } 

      axios
        .post(
          url,
          payload,
          {
            headers: {
              Authorization: `Bearer ${this.token}`, // send the access token through the 'Authorization' header
            },
          }
        )
        .then(() => {
          this.callApi(loader);
        });
    },
    uploadDocument() {
      var formData = new FormData();
      formData.append("file", this.incidentFile);
      formData.append("author", this.$auth.user.name);
      formData.append("expiryDate", "");
      formData.append("documentType", "Other Documents");
      axios.post(
        this.file_api_url + 'owner/building/id/' + this.$props.buildingId + "/" + this.incidentFile.name,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${this.token}`,
          },
        }
      );
    },
    async deleteIncident(id) {
      const url = this.incidents_api_url + id;

      this.$dialog
        .confirm("are you sure?", {
          verification: "confirm",
          type: "hard",
          okText: "Delete",
        })
        .then(() => {
          let loader = this.$loading.show({
            // Optional parameters
            container: this.fullPage ? null : this.$refs.formContainer,
            canCancel: true,
            onCancel: this.onCancel,
          });

          axios
            .delete(url, {
              headers: {
                Authorization: `Bearer ${this.token}`, // send the access token through the 'Authorization' header
              },
            })
            .then(() => {
              loader.hide();
              this.callApi(loader);
            });
        });
    },
    async editIncident(incidentId) {
      const url = this.incidents_api_url + incidentId;

      this.$dialog
        .confirm("are you sure?", {
          clicksCount: 3,
          type: "soft",
          okText: "Edit",
        })
        .then(() => {
          let loader = this.$loading.show({
            // Optional parameters
            container: this.fullPage ? null : this.$refs.formContainer,
            canCancel: true,
            onCancel: this.onCancel,
          });

          axios
            .patch(
              url,
              {
                description: `'${this.$refs["incidentDescription-" + incidentId][0].value.trim()}'`,
                incident_date: `'${this.$refs["incidentDate-" + incidentId][0].value.trim()}'`,
              },
              {
                headers: {
                  Authorization: `Bearer ${this.token}`, // send the access token through the 'Authorization' header
                },
              }
            )
            .then(() => {
              this.callApi(loader);
            });
        });
    },
    async deleteRoom(roomId) {
      const url = this.rooms_api_url + roomId;

      this.$dialog
        .confirm("are you sure?", {
          verification: "confirm",
          type: "hard",
          okText: "Delete",
        })
        .then(() => {
          let loader = this.$loading.show({
            // Optional parameters
            container: this.fullPage ? null : this.$refs.formContainer,
            canCancel: true,
            onCancel: this.onCancel,
          });

          axios
            .delete(url, {
              headers: {
                Authorization: `Bearer ${this.token}`, // send the access token through the 'Authorization' header
              },
            })
            .then(() => {
              this.callApi(loader);
            });
        });
    },
    async editRoom(roomId) {
      const url = this.rooms_api_url + roomId;

      this.$dialog
        .confirm("are you sure?", {
          clicksCount: 3,
          type: "soft",
          okText: "Edit",
        })
        .then(() => {
          let loader = this.$loading.show({
            // Optional parameters
            container: this.fullPage ? null : this.$refs.formContainer,
            canCancel: true,
            onCancel: this.onCancel,
          });

          axios
            .patch(
              url,
              {
                name: `'${this.$refs["roomName-" + roomId][0].value.trim()}'`,
                dimensions: `'${this.$refs[
                  "roomDimensions-" + roomId
                ][0].value.trim()}'`,
                max_occupancy: this.$refs["roomMaxOccupancy-" + roomId][0]
                  .value,
              },
              {
                headers: {
                  Authorization: `Bearer ${this.token}`, // send the access token through the 'Authorization' header
                },
              }
            )
            .then(() => {
              this.callApi(loader);
            });
        });
    },
    async callApi(loader) {
      // Get the access token from the auth wrapper
      this.token = await this.$auth.getTokenSilently();

      // Use Axios to make a call to the API
      const buildingData = await axios.get(
        this.buildings_api_url + this.$props.buildingId,
        {
          headers: {
            Authorization: `Bearer ${this.token}`, // send the access token through the 'Authorization' header
          },
        }
      );

      this.building = buildingData.data[0];
      this.startDate = this.building.start_date;

      // Use Axios to make a call to the API
      const roomsData = await axios.get(
        this.buildings_api_url + this.$props.buildingId + "/rooms",
        {
          headers: {
            Authorization: `Bearer ${this.token}`, // send the access token through the 'Authorization' header
          },
        }
      );

      this.rooms = roomsData.data;
      this.noRooms = this.rooms.length;

      // Use Axios to make a call to the API
      const incidentsData = await axios.get(
        this.incidents_api_url + "building/" + this.$props.buildingId,
        {
          headers: {
            Authorization: `Bearer ${this.token}`, // send the access token through the 'Authorization' header
          },
        }
      );

      this.incidents = incidentsData.data;
      this.noIncidents = this.incidents.length;

      for (var room in this.rooms) {
        this.current_occupancy += this.rooms[room].current_occupancy;
      }

      if (this.building.landlord_id != null) {
        const landlordData = await axios.get(
          this.landlord_api_url + this.building.landlord_id,
          {
            headers: {
              Authorization: `Bearer ${this.token}`, // send the access token through the 'Authorization' header
            },
          }
        );

        this.landlord = landlordData.data[0];
      }

      this.noNotes = await this.$refs.notes.getNotes();
      this.noDefects = await this.$refs.defects.getDefects();
      await this.$refs.inventory.refresh(this.token);
      await this.$refs.utilities.refresh(loader,this.token);
      this.noDocuments = await this.$refs.documents.getDocuments(loader);
      this.edit = false;
      this.$forceUpdate();
      loader.hide();
    },
  },
};
</script>

<style scoped></style>
