<template>
  <div class="Defects">
                <section class="section-shaped my-0">
      <div class="shape shape-style-1 shape-default shape-skew">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    <h1 class="display-1 text-secondary">
      <i class="fa fa-wrench" aria-hidden="true"></i> Defects
    </h1>

    <div class="jumbotron text-left">
      <Defects ref="defects" :token="token" :enableCreate="false" />
    </div>
                </section>
  </div>
</template>

<script>

import Defects from "@/components/Defects";

export default {
  name: 'DefectsView',
  components: {
    Defects
  },
  data() {
    return {
      token: ''
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    async init(){
      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: true,
        onCancel: this.onCancel,
      });
      this.token = await this.$auth.getTokenSilently()
      this.$refs.defects.refresh(loader,this.token)
    }
  }
}
</script>