<template>
  <div id="app">
    <NavBar/>
    <main>
            <fade-transition origin="center" mode="out-in" :duration="250">
    <router-view/>
    </fade-transition>
    </main>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import { FadeTransition } from "vue2-transitions";

export default {
  name: 'Root',
  components:{
    NavBar,
    FadeTransition
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

#nav {
  padding: 20px;
}

#nav a {
  font-weight: bold;
}

#nav a.router-link-exact-active {
  color: #2DCE89;
}

/* .icon,
.text {
vertical-align: middle;
display: inline-block;
} */
</style>
