<template>
  <div class="Reports">
    <section class="section-shaped my-0">
      <div class="shape shape-style-1 shape-default shape-skew">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <h1 class="display-1 text-secondary">
        <i class="fa fa-book" aria-hidden="true"></i> Reports
      </h1>
      <div class="jumbotron text-left container">
        <h3>Statistics</h3>
        <div class="row"><p></p></div>
        <div class="row">
          <div class="col">
            <div class="shadow p-3 mb-5 bg-white rounded">
              Number of Properties: <b>{{ statistics[0].no_buildings }}</b>
            </div>
          </div>
          <div class="col">
            <div class="shadow p-3 mb-5 bg-white rounded">
              Number of Rooms(<b class="text-danger">empty</b>):
              <b>{{ statistics[0].no_rooms }}</b
              >/<b class="text-danger">{{ emptyRooms[0].total_empty_rooms }}</b
              ><br />
              Number of Free Beds: <b>{{ emptyRooms[0].total_free_beds }}</b>
            </div>
          </div>
          <div class="col">
            <div class="shadow p-3 mb-5 bg-white rounded">
              Number of Landlords: <b>{{ statistics[0].no_landlords }}</b>
            </div>
          </div>
          <div class="col">
            <div class="shadow p-3 mb-5 bg-white rounded">
              Number of Tenants: <b>{{ statistics[0].no_tenants }}</b>
            </div>
          </div>
          <div class="col">
            <div class="shadow p-3 mb-5 bg-white rounded">
              Number of Files: <b>{{ statistics[0].no_files }}</b>
            </div>
          </div>
          <div class="col">
            <div class="shadow p-3 mb-5 bg-white rounded">
              Number of Defects (uncompleted/completed):

              <b class="text-danger">{{ statistics[0].uncompleted_jobs }}</b
              >/<b class="text-success">{{ statistics[0].completed_jobs }}</b>
            </div>
          </div>
        </div>
      </div>

      <div class="jumbotron text-left container">
        <tabs>
          <tab-pane title="Current Voids" active>
            <h3>Current Voids</h3>
            <div class="row">
              <div class="col-sm text-right">
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="downloadCSV"
                >
                  <span class="text">Download</span>&#32;
                  <svg
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    class="bi bi-file-earmark-arrow-down-fill"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M2 2a2 2 0 0 1 2-2h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm7 2l.5-2.5 3 3L10 5a1 1 0 0 1-1-1zm-.5 3.5a.5.5 0 0 0-1 0v3.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 2a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 11.293V7.5z"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div class="row"><p></p></div>
            <div class="row">
              <table class="table table-hover">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col">Address</th>
                    <th scope="col">Room</th>
                    <th scope="col">Size</th>
                    <th scope="col">Free Beds</th>
                  </tr>
                </thead>
                <tbody v-for="room in emptyRooms" :key="room.room_name">
                  <tr>
                    <td>
                      <router-link
                        :to="{
                          name: 'BuildingProfile',
                          query: { buildingId: room.building_id },
                        }"
                      >
                        <button class="btn btn-primary" type="button">
                          <svg
                            width="1em"
                            height="1em"
                            viewBox="0 0 16 16"
                            class="bi bi-building"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694L1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"
                            />
                            <path
                              d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z"
                            /></svg
                          >&#32;
                          <span class="text"> {{ room.address }}</span>
                        </button>
                      </router-link>
                    </td>
                    <td>{{ room.room_name }}</td>
                    <td>{{ room.size }}</td>
                    <td>{{ room.free_beds }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </tab-pane>
          <tab-pane title="Movements">
            <div class="row">
              <div class="col">
                <b-form-select
                  v-model="movementMonth"
                  :options="movementMonthOptions"
                  @change="updateMovement"
                ></b-form-select>
              </div>
              <div class="col">
                <b-form-select
                  v-model="movementYear"
                  :options="movementYearOptions"
                  @change="updateMovement"
                ></b-form-select>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <h1 class="text-center">Move In</h1>
                <div v-for="movement in sortedArrayMI" :key="movement.tenant_id">
                  <p
                    v-if="
                      new Date(movement.move_in_date).getMonth() + 1 ==
                        movementMonth &&
                        new Date(movement.move_in_date).getFullYear() ==
                          movementYear
                    "
                  >
                    {{ movement.reference }} - {{ movement.first_name }}
                    {{ movement.family_name }} <br />
                    {{ movement.no_name }}, {{ movement.street }},
                    {{ movement.borough }}. {{ movement.postcode }} <br />
                    {{
                      new Date(movement.move_in_date)
                        .toLocaleDateString("en-GB")
                        .replaceAll("/", "-")
                    }}
                    <i
                      class="fa fa-arrow-right"
                      style="color:green"
                      aria-hidden="true"
                    ></i
                    >{{ movement.move_in_days }} days
                  </p>
                </div>
              </div>
              <div class="col">
                <h1 class="text-center">Move Out</h1>
                <div v-for="movement in sortedArrayMO" :key="movement.tenant_id">
                  <p
                    v-if="
                      new Date(movement.move_out_date).getMonth() + 1 ==
                        movementMonth &&
                        movement.move_out_date &&
                        new Date(movement.move_out_date).getFullYear() ==
                          movementYear
                    "
                  >
                    {{ movement.reference }} - {{ movement.first_name }}
                    {{ movement.family_name }} <br />
                    {{ movement.no_name }}, {{ movement.street }},
                    {{ movement.borough }}. {{ movement.postcode }} <br />
                    {{
                      new Date(movement.move_out_date)
                        .toLocaleDateString("en-GB")
                        .replaceAll("/", "-")
                    }}
                    <i
                      class="fa fa-arrow-right"
                      style="color:red"
                      aria-hidden="true"
                    ></i
                    >{{ movement.move_out_days }} days
                  </p>
                </div>
              </div>
            </div>
          </tab-pane>
        </tabs>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import app_config from "../../app_config.json";
import getEnv from "@/utils/env";
import { ExportToCsv } from "export-to-csv";
import Tabs from "@/components/Tabs/Tabs.vue";
import TabPane from "@/components/Tabs/TabPane.vue";

export default {
  name: "Reports",
  components: {
    Tabs,
    TabPane,
  },
  computed: {
    sortedArrayMO: function() {
      let sorted = [...this.movements]
      function compare(a, b) {
        if (a.move_out_days < b.move_out_days) return -1;
        if (a.move_out_days > b.move_out_days) return 1;
        return 0;
      }

      return sorted.sort(compare);
    },
    sortedArrayMI: function() {
      let sorted = [...this.movements]
      function compare(a, b) {
        if (a.move_in_days < b.move_in_days) return -1;
        if (a.move_in_days > b.move_in_days) return 1;
        return 0;
      }

      return sorted.sort(compare);
    },
  },
  data() {
    return {
      reports_api_url: app_config[getEnv("VUE_APP_ENV")].reports_api_url,
      emptyRooms: [],
      statistics: [],
      movements: [],
      movementMonth: null,
      movementYear: null,
      movementMonthOptions: [
        { value: "1", text: "January" },
        { value: "2", text: "February" },
        { value: "3", text: "March" },
        { value: "4", text: "April" },
        { value: "5", text: "May" },
        { value: "6", text: "June" },
        { value: "7", text: "July" },
        { value: "8", text: "August" },
        { value: "9", text: "September" },
        { value: "10", text: "October" },
        { value: "11", text: "November" },
        { value: "12", text: "December" },
      ],
      movementYearOptions: [],
    };
  },
  created() {
    for (var i = 1980; i < 2101; i++) {
      this.movementYearOptions[i - 1980] = { value: i, text: String(i) };
    }

    this.movementYear = new Date().getFullYear();
    this.movementMonth = new Date().getMonth() + 1;

    let loader = this.$loading.show({
      // Optional parameters
      container: this.fullPage ? null : this.$refs.formContainer,
      canCancel: true,
      onCancel: this.onCancel,
    });

    this.callApi(loader);
  },
  methods: {
    async updateMovement() {
      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: true,
        onCancel: this.onCancel,
      });

      this.callApi(loader);
    },
    async callApi(loader) {
      // Get the access token from the auth wrapper
      this.token = await this.$auth.getTokenSilently();

      // Use Axios to make a call to the API
      const emptyRoomsData = await axios.get(
        this.reports_api_url + "emptyRooms",
        {
          headers: {
            Authorization: `Bearer ${this.token}`, // send the access token through the 'Authorization' header
          },
        }
      );

      this.emptyRooms = emptyRoomsData.data;

      const statisticsData = await axios.get(
        this.reports_api_url + "statistics",
        {
          headers: {
            Authorization: `Bearer ${this.token}`, // send the access token through the 'Authorization' header
          },
        }
      );

      this.statistics = statisticsData.data;

      const movementsData = await axios.get(
        this.reports_api_url + "movements",
        {
          headers: {
            Authorization: `Bearer ${this.token}`, // send the access token through the 'Authorization' header
          },
          params: {
            month: this.movementMonth,
            year: this.movementYear,
          },
        }
      );

      this.movements = movementsData.data;

      this.$forceUpdate();
      loader.hide();
    },
    downloadCSV() {
      const options = {
        fieldSeparator: ",",
        quoteStrings: '"',
        decimalSeparator: ".",
        showLabels: true,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        filename: "buildings",
        // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
      };

      const csvExporter = new ExportToCsv(options);

      csvExporter.generateCsv(this.emptyRooms);
    },
  },
};
</script>
