import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue } from 'bootstrap-vue'
import getEnv from "@/utils/env";
import VuejsDialog from 'vuejs-dialog';
import 'vuejs-dialog/dist/vuejs-dialog.min.css';
import Argon from "./plugins/argon-kit";
import './registerServiceWorker'

// Import the Auth0 configuration
import auth_config from "../auth_config.json";

// Import the plugin here
import { Auth0Plugin } from "./auth";

import Loading from 'vue-loading-overlay';

import 'vue-loading-overlay/dist/vue-loading.css';
let domain = auth_config[getEnv('VUE_APP_ENV')].domain
let clientId = auth_config[getEnv('VUE_APP_ENV')].clientId
let audience = auth_config[getEnv('VUE_APP_ENV')].audience

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : '/buildings'
    );
  }
});

Vue.use(Loading);

Vue.use(BootstrapVue)

Vue.use(VuejsDialog);

Vue.use(Argon);

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
