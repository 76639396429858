import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Profile from '../views/Profile.vue';
import Buildings from "../views/Buildings.vue";
import Tenants from '../views/Tenants.vue';
import Landlords from '../views/Landlords.vue';
import LandlordProfile from '../views/LandlordProfile.vue';
import TenantProfile from "@/views/TenantProfile";
import BuildingProfile from "@/views/BuildingProfile";
import Reports from "@/views/Reports"
import Documents from '@/views/DocumentsView'
import Defects from '@/views/DefectsView'
import { authGuard } from "../auth/authGuard";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    beforeEnter: authGuard
  },
  {
      path: "/buildings",
      name: "Buildings",
      component: Buildings,
      beforeEnter: authGuard
  },
  {
    path: '/buildingProfile',
    name: 'BuildingProfile',
    component: BuildingProfile,
    beforeEnter: authGuard,
    props: route => ({ buildingId: route.query.buildingId })
  },
  {
    path: '/tenants',
    name: 'Tenants',
    component: Tenants,
    beforeEnter: authGuard,
  },
  {
    path: '/landlords',
    name: 'Landlords',
    component: Landlords,
    beforeEnter: authGuard,
  },
  {
    path: '/landlordProfile',
    name: 'LandlordProfile',
    component: LandlordProfile,
    beforeEnter: authGuard,
    props: route => ({landlordId: route.query.landlordId})
  },
  {
    path: '/tenantProfile',
    name: 'TenantProfile',
    component: TenantProfile,
    beforeEnter: authGuard,
    props: route => ({ tenantId: route.query.tenantId })
  },
  {
    path: '/reports',
    name: 'Reports',
    component: Reports,
    beforeEnter: authGuard
  },
  {
    path: '/documents',
    name: 'Documents',
    component: Documents,
    beforeEnter: authGuard,
    props: route => ({ file: route.query.file })
  },
  {
    path: '/defects',
    name: 'Defects',
    component: Defects,
    beforeEnter: authGuard
  }
]

const router = new VueRouter({
  routes
})

export default router
