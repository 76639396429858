<template>
  <div class="tenants">
            <section class="section-shaped my-0">
      <div class="shape shape-style-1 shape-default shape-skew">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    <h1 class="display-1 text-secondary">
        <i class="fa fa-user-circle" aria-hidden="true"></i> Tenants
    </h1>
    <div class="container">
      <div class="jumbotron">
        <div class="row">
          <div class="col text-left">
            <a
              class="btn btn-success"
              data-toggle="collapse"
              href="#newTenant"
              role="button"
              aria-expanded="false"
              aria-controls="collapse"
            >
              <svg
                  width="1em"
                  height="1em"
                  viewBox="0 0 16 16"
                  class="bi bi-plus-circle-fill"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"
                  /></svg
                ><span class="text">New Tenant</span>
            </a>
            &#32;
            <a
              class="btn btn-primary"
              data-toggle="collapse"
              href="#search"
              role="button"
              aria-expanded="false"
              aria-controls="collapse"
            >
              <i class="bi bi-search"></i> Search
            </a>
          </div>
          <div class="col text-right">
            <button type="button" class="btn btn-primary" @click="downloadCSV">
              <span class="text">Download</span>
              &#32;
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                class="bi bi-file-earmark-arrow-down-fill"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M2 2a2 2 0 0 1 2-2h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm7 2l.5-2.5 3 3L10 5a1 1 0 0 1-1-1zm-.5 3.5a.5.5 0 0 0-1 0v3.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 2a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 11.293V7.5z"
                />
              </svg>
            </button>
          </div>
        </div>
        <div class="row">
          <p></p>
        </div>
        <div class="accordion-group" id="formGroup">
          <div class="row">
            <div class="col text-left">
              <div class="collapse" id="newTenant" data-parent="#formGroup">
                <div class="jumbotron jumbotron-fluid">
                  <form class="container">
                    <h3>Tenant</h3>
                    <div class="form-row">
                      <div class="form-group col">
                        <label for="tenantReference">Reference:</label>
                        <input
                          type="text"
                          class="form-control"
                          id="tenantReference"
                          ref="tenantReference"
                        />
                      </div>
                      <div class="form-group col"></div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col">
                        <label for="tenantName">First Name:</label>
                        <input
                          type="text"
                          class="form-control"
                          id="tenantName"
                          ref="tenantName"
                          placeholder="First Name"
                        />
                      </div>
                      <div class="form-group col">
                        <label for="tenantFamilyName">Family Name:</label>
                        <input
                          type="text"
                          class="form-control"
                          id="tenantFamilyName"
                          ref="tenantFamilyName"
                          placeholder="Family Name"
                        />
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col">
                        <label for="tenantEmail">Email:</label>
                        <input
                          type="email"
                          class="form-control"
                          id="tenantEmail"
                          ref="tenantEmail"
                          placeholder="Email"
                        />
                      </div>
                      <div class="form-group col">
                        <label for="tenantNumber">Mobile Number:</label>
                        <div v-for="index in mobile_nos" :key="index">
                        <input
                          type="tel"
                          class="form-control"
                          id="tenantNumber"
                          ref="tenantNumber"
                          placeholder="Mobile Number"
                        />
                        <br>
                        </div>
                        <a
                          class="text-danger"
                          role="button"
                          @click="mobile_nos--"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-dash-circle-fill"
                            viewBox="0 0 16 16"
                          >
                            <path
                              d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7z"
                            />
                          </svg>
                        </a>
                        <a
                          class="text-success"
                          role="button"
                          @click="mobile_nos++"
                        >
                          <svg
                            width="1em"
                            height="1em"
                            viewBox="0 0 16 16"
                            class="bi bi-plus-circle-fill"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"
                            />
                          </svg>
                        </a>
                      </div>
                    </div>
                    <h3>Residence</h3>
                    <div class="form-row">
                      <div class="col">
                        <label for="property">Building:</label>
                        <select
                          class="form-control"
                          name="property"
                          ref="property"
                          id="property"
                          @change="getRooms()"
                        >
                          <option></option>
                          <option
                            v-for="building in buildings"
                            :key="building.id"
                            :value="building.id"
                            >{{ building.no_name }},{{ building.street }},{{
                              building.postcode
                            }}
                          </option>
                        </select>
                      </div>
                      <div class="col">
                        <label for="rooms">Room:</label>
                        <select
                          class="form-control"
                          name="rooms"
                          ref="rooms"
                          id="rooms"
                        >
                          <option
                            v-for="room in maxOccupants"
                            :key="room.id"
                            :value="room.id"
                          >
                            {{ room.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col">
                        <label for="moveInDate">Move In Date:</label>
                        <input
                          class="form-control"
                          type="date"
                          name="moveInDate"
                          id="moveInDate"
                          ref="moveInDate"
                        />
                      </div>
                      <div class="col"></div>
                    </div>
                    <div class="form-row">
                      <div class="col">
                        <p></p>
                        <button
                          type="submit"
                          class="btn btn-success"
                          @click.prevent="createTenant"
                        >
                          Create
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col text-left">
              <div class="collapse" id="search" data-parent="#formGroup">
                <div class="jumbotron jumbotron-fluid">
                  <form class="container">
                    <div class="form-row">
                      <div class="form-group col">
                        <label for="tenantReference">Reference:</label>
                        <input
                          type="text"
                          class="form-control"
                          id="tenantSearchReference"
                          ref="tenantSearchReference"
                        />
                      </div>
                      <div class="form-group col"></div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col">
                        <label for="tenantName">First Name:</label>
                        <input
                          type="text"
                          class="form-control"
                          id="tenantSearchName"
                          ref="tenantSearchName"
                          placeholder="First Name"
                        />
                      </div>
                      <div class="form-group col">
                        <label for="tenantFamilyName">Family Name:</label>
                        <input
                          type="text"
                          class="form-control"
                          id="tenantSearchFamilyName"
                          ref="tenantSearchFamilyName"
                          placeholder="Family Name"
                        />
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col">
                        <label for="tenantEmail">Email:</label>
                        <input
                          type="email"
                          class="form-control"
                          id="tenantSearchEmail"
                          ref="tenantSearchEmail"
                          placeholder="Email"
                        />
                      </div>
                      <div class="form-group col">
                        <label for="tenantNumber">Mobile Number:</label>
                        <input
                          type="tel"
                          class="form-control"
                          id="tenantSearchNumber"
                          ref="tenantSearchNumber"
                          placeholder="Mobile Number"
                        />
                      </div>
                    </div>
                    <button
                      type="submit"
                      class="btn btn-success"
                      @click.prevent="search"
                    >
                      Search
                    </button>
                    &#32;
                    <button
                      type="submit"
                      class="btn btn-primary"
                      @click.prevent="clearSearch"
                    >
                      Clear Filters
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <p></p>
          </div>
        </div>
        <div class="row">
          <table class="table table-hover">
            <thead class="thead-dark">
              <tr>
                <th scope="col">Reference</th>
                <th scope="col">First Name</th>
                <th scope="col">Family Name</th>
                <th scope="col">Email</th>
                <th scope="col">Mobile Number</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody v-for="tenant in tenants" :key="tenant.id">
              <tr>
                <td>{{ tenant.reference }}</td>
                <td>{{ tenant.first_name }}</td>
                <td>{{ tenant.family_name }}</td>
                <td>
                  <a :href="'mailto:' + tenant.email">{{ tenant.email }}</a>
                </td>
                <td>
                  <a  v-for="number in tenant.mobile_no.split(',')" :key="number" :href="'tel:' + tenant.mobile_no">{{
                    number
                  }}<br></a>
                </td>
                <td>
                  <router-link
                    :to="{
                      name: 'TenantProfile',
                      query: { tenantId: tenant.id },
                    }"
                  >
                    <button class="btn btn-primary" type="button" name="button">
                      <svg
                        width="1em"
                        height="1em"
                        viewBox="0 0 16 16"
                        class="bi bi-person-lines-fill"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm7 1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm2 9a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"
                        /></svg
                      >&#32;
                      <span class="text">Full Profile</span>
                    </button>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <ul class="pagination justify-content-center">
            <li class="page-item">
              <a class="page-link" @click="changePage(false)">&laquo;</a>
            </li>
            <li
              v-for="n in pages"
              :key="n"
              :class="n === current_page ? 'page-item active' : 'page-item'"
            >
              <a class="page-link" @click="changePage(n)">{{ n }}</a>
            </li>
            <li class="page-item">
              <a class="page-link" @click="changePage(true)">&raquo;</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
            </section>
  </div>
</template>

<script>
import axios from "axios";
import { ExportToCsv } from "export-to-csv";
import app_config from "../../app_config.json";
import getEnv from "@/utils/env";

export default {
  name: "Tenants",
  data() {
    return {
      tenants: "",
      token: "",
      people_api_url: app_config[getEnv("VUE_APP_ENV")].people_api_url,
      buildings_api_url: app_config[getEnv("VUE_APP_ENV")].buildings_api_url,
      rooms_api_url: app_config[getEnv("VUE_APP_ENV")].rooms_api_url,
      buildings: [],
      rooms: [],
      pages: 1,
      current_page: 1,
      mobile_nos: 1,
    };
  },
  props: {
    buildingId: Number,
  },
  created() {
    let loader = this.$loading.show({
      // Optional parameters
      container: this.fullPage ? null : this.$refs.formContainer,
      canCancel: true,
      onCancel: this.onCancel,
    });

    this.callApi(loader);
  },
  computed: {
    maxOccupants: function() {
      return this.rooms.filter(function(r) {
        return r.max_occupancy != r.current_occupancy;
      });
    },
  },
  methods: {
    async createResidence(loader, tenant_id) {
      const url = this.people_api_url + "tenant/" + tenant_id + "/residence";

      axios
        .post(
          url,
          {
            move_in_date: this.$refs.moveInDate.value,
            room_id: this.$refs.rooms.value,
            tenant: tenant_id,
          },
          {
            headers: {
              Authorization: `Bearer ${this.token}`, // send the access token through the 'Authorization' header
            },
          }
        )
        .then(
          () => {
            this.callApi(loader);
          }
        );
    },
    async getRooms() {
      if (this.$refs.property.value == "") {
        return;
      }
      const url = this.buildings_api_url + this.$refs.property.value + "/rooms";

      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: true,
        onCancel: this.onCancel,
      });

      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${this.token}`, // send the access token through the 'Authorization' header
          },
        })
        .then(
          (data) => {
            this.rooms = data["data"];
            this.callApi(loader);
          }
        );
    },
    async createTenant() {
      let phonenumbers = "";
      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: true,
        onCancel: this.onCancel,
      });

      const url = this.people_api_url + "tenant/";

      this.$refs.tenantNumber.forEach(function(phonenumber) {
        phonenumbers += phonenumber.value + ",";
      });

      phonenumbers = phonenumbers.slice(0, -1);

      axios
        .post(
          url,
          {
            first_name: this.$refs.tenantName.value.trim(),
            family_name: this.$refs.tenantFamilyName.value.trim(),
            email: this.$refs.tenantEmail.value.trim(),
            mobile_no: phonenumbers,
            reference: this.$refs.tenantReference.value.trim(),
          },
          {
            headers: {
              Authorization: `Bearer ${this.token}`, // send the access token through the 'Authorization' header
            },
          }
        )
        .then(
          (response) => {
            this.$refs.tenantName.value = "";
            this.$refs.tenantFamilyName.value = "";
            this.$refs.tenantEmail.value = "";
            this.$refs.tenantNumber.value = "";
            this.$refs.tenantReference.value = "";
            if (this.$refs.moveInDate.value && this.$refs.rooms.value) {
              this.createResidence(loader, response.data[0].id);
            } else {
              this.callApi(loader);
            }
          }
        );
    },
    downloadCSV() {
      const options = {
        fieldSeparator: ",",
        quoteStrings: '"',
        decimalSeparator: ".",
        showLabels: true,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        filename: "tenants",
        // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
      };

      const csvExporter = new ExportToCsv(options);

      csvExporter.generateCsv(this.tenants);
    },
    async callApi(loader) {
      // Get the access token from the auth wrapper
      this.token = await this.$auth.getTokenSilently();
      let url = this.people_api_url + "tenant/";
      let params = {};

      params["page"] = this.current_page;

      if (this.$refs.tenantSearchReference.value.trim().length > 0) {
        params['reference'] = this.$refs.tenantSearchReference.value.trim()
      }

      if (this.$refs.tenantSearchName.value.trim().length > 0) {
        params['first_name'] = this.$refs.tenantSearchName.value.trim()
      }

      if (this.$refs.tenantSearchFamilyName.value.trim().length > 0) {
        params['family_name'] = this.$refs.tenantSearchFamilyName.value.trim()
      }

      if (this.$refs.tenantSearchEmail.value.trim().length > 0) {
        params['email'] = this.$refs.tenantSearchEmail.value.trim()
      }

      if (this.$refs.tenantSearchNumber.value.trim().length > 0) {
        params['mobile_no'] = this.$refs.tenantSearchNumber.value.trim()
      }

      // Use Axios to make a call to the API
      const { data } = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${this.token}`, // send the access token through the 'Authorization' header
        },
        params: params,
      });

      url = this.buildings_api_url;

      //Use Axios to make a call to the API
      const buildingsData = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${this.token}`, // send the access token through the 'Authorization' header
        },
      });

      this.buildings = buildingsData["data"];

      this.tenants = data;
      this.pages = this.tenants[0].no_pages;
      this.$forceUpdate();
      loader.hide();
    },
    async changePage(up) {
      if (typeof up == "boolean") {
        if (up) {
          this.current_page === this.pages ? null : this.current_page++;
        } else {
          this.current_page === 1 ? null : this.current_page--;
        }
      } else {
        this.current_page = up;
      }
      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: true,
        onCancel: this.onCancel,
      });
      this.callApi(loader);
    },
    async clearSearch() {
      this.$refs.tenantSearchName.value = ''
      this.$refs.tenantSearchReference.value = ''
      this.$refs.tenantSearchFamilyName.value = ''
      this.$refs.tenantSearchEmail.value = ''
      this.$refs.tenantSearchNumber.value = ''

      this.search()
    },
    async search() {
      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: true,
        onCancel: this.onCancel,
      });

      this.callApi(loader)
    },
  },
};
</script>
