<template>
  <div class="landlordProfile">
                <section class="section-shaped my-0">
      <div class="shape shape-style-1 shape-default shape-skew">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
<h1 class="display-1 text-secondary">
      <i class="fa fa-user-circle" aria-hidden="true"></i> Landlord
      </h1>
    <div class="container">
      <div class="jumbotron">
        <div class="text-left">
          <button
            class="btn btn-danger"
            type="button"
            @click="deleteLandlord()"
          >
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              class="bi bi-trash-fill"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z"
              /></svg
            >&#32;
            <span class="text">Delete</span>
          </button>
          &#32;
          <button class="btn btn-primary" type="button" @click="editText()">
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              class="bi bi-pencil-fill"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"
              /></svg
            >&#32;
            <span class="text">Edit</span>
          </button>
          <p></p>
          <h3><i class="fa fa-pencil" aria-hidden="true"></i> Details</h3>
          <form class="container">
            <div class="form-row">
              <div class="form-group col">
                <label for="landlordName">First Name:</label>
                <input
                  type="text"
                  class="form-control"
                  id="landlordName"
                  ref="landlordName"
                  :value="landlord.first_name"
                  :hidden="!edit"
                />
                <p :hidden="edit">{{ landlord.first_name }}</p>
              </div>
              <div class="form-group col">
                <label for="landlordFamilyName">Family Name:</label>
                <input
                  type="text"
                  class="form-control"
                  id="landlordFamilyName"
                  ref="landlordFamilyName"
                  :value="landlord.family_name"
                  :hidden="!edit"
                />
                <p :hidden="edit">{{ landlord.family_name }}</p>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col">
                <label for="landlordEmail">Email:</label>
                <input
                  type="email"
                  class="form-control"
                  id="landlordEmail"
                  ref="landlordEmail"
                  :value="landlord.email"
                  :hidden="!edit"
                />
                <p :hidden="edit">
                  <a :href="'mailto:' + landlord.email">{{ landlord.email }}</a>
                </p>
              </div>
              <div class="form-group col">
                <label for="landlordNumber">Mobile Number:</label>
                <input
                  type="tel"
                  class="form-control"
                  id="landlordNumber"
                  ref="landlordNumber"
                  :value="landlord.mobile_no"
                  :hidden="!edit"
                />
                <p :hidden="edit">
                  <a v-for="number in landlord.mobile_no.split(',')" :key="number" :href="'tel:' + number">{{
                    number
                  }}<br></a>
                </p>
              </div>
            </div>
          </form>
          <button
            type="submit"
            class="btn btn-success"
            @click.prevent="editLandlord()"
            :hidden="!edit"
          >
            Save Changes
          </button>
          <hr>
          <h3>
            <i class="fa fa-building" aria-hidden="true"></i> Properties
          </h3>
          <table class="table table-hover">
            <tbody
              v-for="landlordsBuilding in landlordsBuildings"
              :key="landlordsBuilding.id"
            >
              <tr>
                <td style="width: 80%">
                  <router-link
                    :to="{
                      name: 'BuildingProfile',
                      query: { buildingId: landlordsBuilding.id },
                    }"
                  >
                    <button class="btn btn-primary" type="button">
                      <svg
                        width="1em"
                        height="1em"
                        viewBox="0 0 16 16"
                        class="bi bi-building"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694L1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"
                        />
                        <path
                          d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z"
                        /></svg
                      >&#32;
                      <span class="text"
                        >{{ landlordsBuilding.no_name }},
                        {{ landlordsBuilding.street }},
                        {{ landlordsBuilding.borough }},
                        {{ landlordsBuilding.postcode }}
                        </span>
                    </button>
                  </router-link>
                </td>
                <td style="width: 20%">
                  <button
                    class="btn btn-danger"
                    type="button"
                    @click="unassign(landlordsBuilding.id)"
                  >
                    <svg
                      width="1em"
                      height="1em"
                      viewBox="0 0 16 16"
                      class="bi bi-trash-fill"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z"
                      /></svg
                    >&#32;
                    <span class="text">Unassign</span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="row">
            <div class="col-auto">
              <button
                class="btn btn-success"
                type="button"
                @click="addProperty()"
              >
                <svg
                  width="1em"
                  height="1em"
                  viewBox="0 0 16 16"
                  class="bi bi-plus-circle-fill"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"
                  /></svg
                >&#32;
                <span class="text">Add Property</span>
              </button>
            </div>
            <div class="col-auto">
              <select class="form-control" name="property" ref="property">
                <option
                  v-for="building in buildings"
                  :key="building.id"
                  :value="building.id"
                  >{{ building.no_name }},{{ building.street }},{{
                    building.postcode
                  }}
                </option>
              </select>
            </div>
          </div>
          <hr>
          <Documents
            ref="documents"
            :token="token"
            :url="'owner/landlord/id/' + landlordId"
          />
          <hr>
          <Notes
            ref="notes"
            :url="url + landlordId + '/note'"
            :token="token"
            :id="landlordId"
            id_column="landlord_id"
          />
        </div>
      </div>
    </div>
                </section>
  </div>
</template>

<script>
import axios from "axios";
import app_config from "../../app_config.json";
import Notes from "@/components/Notes.vue";
import Documents from "@/components/Documents";
import getEnv from "@/utils/env";

export default {
  name: "LandordProfile",
  components: {
    Notes,
    Documents,
  },
  data() {
    return {
      landlord: "",
      token: "",
      edit: false,
      buildings: [],
      landlordsBuildings: [],
      url: app_config[getEnv("VUE_APP_ENV")].people_api_url + "landlord/",
      people_api_url: app_config[getEnv("VUE_APP_ENV")].people_api_url,
      buildings_api_url: app_config[getEnv("VUE_APP_ENV")].buildings_api_url,
    };
  },
  props: {
    landlordId: Number,
  },
  created() {
    let loader = this.$loading.show({
      // Optional parameters
      container: this.fullPage ? null : this.$refs.formContainer,
      canCancel: true,
      onCancel: this.onCancel,
    });

    this.callApi(loader);
  },
  methods: {
    async editText() {
      if (this.edit) {
        this.edit = false;
      } else {
        this.edit = true;
      }
    },
    async addProperty() {
      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: true,
        onCancel: this.onCancel,
      });

      const url =
        this.buildings_api_url +
        this.$refs.property.value +
        "/landlord/" +
        this.$props.landlordId;

      axios
        .post(
          url,
          {},
          {
            headers: {
              Authorization: `Bearer ${this.token}`, // send the access token through the 'Authorization' header
            },
          }
        )
        .then(
          () => {
            this.callApi(loader);
          }
        );
    },
    async unassign(buildingId) {
      this.$dialog
        .confirm("are you sure?", {
          clicksCount: 3,
          type: "soft",
          okText: "Unassign",
        })
        .then(() => {
          let loader = this.$loading.show({
            // Optional parameters
            container: this.fullPage ? null : this.$refs.formContainer,
            canCancel: true,
            onCancel: this.onCancel,
          });

          const url = this.buildings_api_url + buildingId + "/landlord";

          axios
            .delete(url, {
              headers: {
                Authorization: `Bearer ${this.token}`, // send the access token through the 'Authorization' header
              },
            })
            .then(
              () => {
                this.callApi(loader);
              }
            );
        });
    },
    async deleteLandlord() {
      const url = this.people_api_url + "landlord/" + this.$props.landlordId;

      this.$dialog
        .confirm("are you sure?", {
          verification: "confirm",
          type: "hard",
          okText: "Delete",
        })
        .then(() => {
          let loader = this.$loading.show({
            // Optional parameters
            container: this.fullPage ? null : this.$refs.formContainer,
            canCancel: true,
            onCancel: this.onCancel,
          });

          axios
            .delete(url, {
              headers: {
                Authorization: `Bearer ${this.token}`, // send the access token through the 'Authorization' header
              },
            })
            .then(
              () => {
                loader.hide();
                this.$router.push("landlords");
              }
            );
        });
    },
    async editLandlord() {
      const url = this.people_api_url + "landlord/" + this.$props.landlordId;

      this.$dialog
        .confirm("are you sure?", {
          clicksCount: 3,
          type: "soft",
          okText: "Edit",
        })
        .then(() => {
          let loader = this.$loading.show({
            // Optional parameters
            container: this.fullPage ? null : this.$refs.formContainer,
            canCancel: true,
            onCancel: this.onCancel,
          });

          axios
            .patch(
              url,
              {
                first_name: `'${this.$refs["landlordName"].value.trim()}'`,
                family_name: `'${this.$refs[
                  "landlordFamilyName"
                ].value.trim()}'`,
                email: `'${this.$refs["landlordEmail"].value.trim()}'`,
                mobile_no: `'${this.$refs["landlordNumber"].value.trim()}'`,
              },
              {
                headers: {
                  Authorization: `Bearer ${this.token}`, // send the access token through the 'Authorization' header
                },
              }
            )
            .then(
              () => {
                this.callApi(loader);
              }
            );
        });
    },
    async callApi(loader) {
      // Get the access token from the auth wrapper
      this.token = await this.$auth.getTokenSilently();
      var url = this.people_api_url + "landlord/" + this.$props.landlordId;

      // Use Axios to make a call to the API
      const landlordData = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${this.token}`, // send the access token through the 'Authorization' header
        },
      });

      this.landlord = landlordData["data"][0];

      url = this.buildings_api_url + "landlord/null";

      //Use Axios to make a call to the API
      const buildingsData = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${this.token}`, // send the access token through the 'Authorization' header
        },
      });

      this.buildings = buildingsData["data"];

      url = this.buildings_api_url + "landlord/" + this.$props.landlordId;

      //Use Axios to make a call to the API
      const landlordBuildingsData = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${this.token}`, // send the access token through the 'Authorization' header
        },
      });

      this.landlordsBuildings = landlordBuildingsData["data"];

      this.$refs.notes.getNotes();
      this.$refs.documents.getDocuments();
      this.edit = false;
      this.$forceUpdate();
      loader.hide();
    },
  },
};
</script>
