<template>
  <div class="documents">
    <h3><i class="fa fa-file-text" aria-hidden="true"></i> Documents</h3>
    <div class="row">
      <div class="col text-left">
        <a
          class="btn btn-primary"
          data-toggle="collapse"
          href="#search"
          role="button"
          aria-expanded="false"
          aria-controls="collapse"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-search"
            viewBox="0 0 16 16"
          >
            <path
              d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"
            />
          </svg>
          &#32;
          <span class="text">Search</span>
        </a>
        <button type="button" class="btn btn-primary" @click="downloadCSV">
          <span class="text">Report</span>&#32;
          <svg
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            class="bi bi-file-earmark-arrow-down-fill"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M2 2a2 2 0 0 1 2-2h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm7 2l.5-2.5 3 3L10 5a1 1 0 0 1-1-1zm-.5 3.5a.5.5 0 0 0-1 0v3.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 2a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 11.293V7.5z"
            />
          </svg>
        </button>
      </div>
      <div class="col-sm text-right">
        <button class="btn btn-danger" type="button" @click="deleteDocument()" :disabled="checkedDocuments.length == 0">
          <svg
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            class="bi bi-trash-fill"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z"
            /></svg
          >&#32;
          <span class="text">Delete</span>
        </button>
        &#32;
        <button class="btn btn-info" type="button" @click="downloadDocument()" :disabled="checkedDocuments.length == 0">
          <svg
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            class="bi bi-file-earmark-arrow-down-fill"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M2 2a2 2 0 0 1 2-2h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm7 2l.5-2.5 3 3L10 5a1 1 0 0 1-1-1zm-.5 3.5a.5.5 0 0 0-1 0v3.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 2a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 11.293V7.5z"
            /></svg
          >&#32;
          <span class="text">Download</span>
        </button>
        <button
          class="btn btn-warning"
          type="button"
          @click="archiveDocument()"
          :disabled="checkedDocuments.length == 0"
        >
          <i class="fa fa-archive"></i>
          <span class="text">Archive</span>
        </button>
      </div>
    </div>
    <div class="collapse" id="search">
      <div class="jumbotron jumbotron-fluid">
        <form class="container">
          <div class="form-row">
            <div class="form-group col">
              <label for="searchFilename">Filename: </label>
              <input
                type="text"
                class="form-control"
                id="searchFilename"
                ref="searchFilename"
                placeholder="Filename"
              />
            </div>
            <div class="form-group col">
              <label for="searchAuthor">Author: </label>
              <input
                type="text"
                class="form-control"
                id="searchAuthor"
                ref="searchAuthor"
                placeholder="Author"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col">
              <label for="searchUploadDate">Upload From Date: </label>
              <input
                type="date"
                class="form-control"
                id="searchUploadFromDate"
                ref="searchUploadFromDate"
              />
            </div>
            <div class="form-group col">
              <label for="searchUploadDate">Upload To Date: </label>
              <input
                type="date"
                class="form-control"
                id="searchUploadToDate"
                ref="searchUploadToDate"
              />
            </div>
            <div class="form-group col">
              <label for="searchExpiryFromDate">Expiry From Date: </label>
              <input
                type="date"
                class="form-control"
                id="searchExpiryFromDate"
                ref="searchExpiryFromDate"
              />
            </div>
            <div class="form-group col">
              <label for="searchExpiryToDate">Expiry To Date: </label>
              <input
                type="date"
                class="form-control"
                id="searchExpiryToDate"
                ref="searchExpiryToDate"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col">
              <label for="searchDocumentType">Document Type: </label>
              <select class="form-control" ref="searchDocumentType">
                <optgroup label="All">
                  <option>All</option>
                </optgroup>
                <optgroup label="Buildings">
                  <option>PPAR</option>
                  <option>Gas Cert</option>
                  <option>Electric Cert</option>
                  <option>EPC</option>
                  <option>Floorplans</option>
                  <option>Fire Alarm Cert</option>
                  <option>Emergency Lighting Cert</option>
                  <option>PAT Cert</option>
                  <option>Fire Risk Assessment</option>
                  <option>HMO Final Licence</option>
                  <option>HMO Draft Licence</option>
                  <option>HMO Application</option>
                  <option>HMO Payment Proof</option>
                  <option>HMO Document</option>
                  <option>Council Document</option>
                </optgroup>
                <optgroup label="People">
                  <option>Passport</option>
                  <option>Driving Licence</option>
                  <option>Proof of ownership</option>
                  <option>Building insurance</option>
                  <option>Terms of Business</option>
                </optgroup>
                <optgroup label="Other">
                  <option>Other Documents</option>
                </optgroup>
              </select>
            </div>
            <div class="form-group col">
              <label for="archived">Show Archived: </label>
              <base-checkbox id="archived" v-model="archived"></base-checkbox>
            </div>
          </div>
          <button type="submit" class="btn btn-success" @click.prevent="search">
            Search
          </button>
          &#32;
          <button
            type="submit"
            class="btn btn-primary"
            @click.prevent="clearSearch"
          >
            Clear Filters
          </button>
        </form>
      </div>
    </div>
    <p></p>
    <table class="table table-hover">
      <thead class="thead-dark">
        <tr>
          <th style="width: 45%">Filename</th>
          <th style="width: 10%">Author</th>
          <th style="width: 10%">Document Type</th>
          <th style="width: 10%">Upload Date</th>
          <th style="width: 10%">Expiry Date</th>
          <th style="width: 5%"></th>
        </tr>
      </thead>
      <tbody v-for="document in documents" :key="document.filename">
        <tr>
          <td>
            {{ document.file_name }}
            <div v-if="document.archived" class="btn-success btn btn-sm">
              Archived
            </div>
          </td>
          <td>{{ document.author }}</td>
          <td>{{ document.document_type }}</td>
          <td>
            {{
              new Date(document.upload_date)
                .toLocaleDateString("en-GB")
                .replaceAll("/", "-") +
                " " +
                new Date(document.upload_date)
                  .toLocaleTimeString("en-GB")
                  .slice(0, -3)
            }}
          </td>
          <td>
            {{
              document.expiry_date
                ? new Date(document.expiry_date)
                    .toLocaleDateString("en-GB")
                    .replaceAll("/", "-")
                : ""
            }}
          </td>
          <td>
            <b-form-checkbox
              class="form-check-input"
              type="checkbox"
              :value="document.file_name"
              :id="document.file_name"
              v-model="checkedDocuments"
            ></b-form-checkbox>
          </td>
        </tr>
      </tbody>
    </table>
    <div>
      <ul class="pagination justify-content-center">
        <li class="page-item">
          <a class="page-link" @click="changePage(false)">&laquo;</a>
        </li>
        <li
          v-for="n in pages"
          :key="n"
          :class="n === current_page ? 'page-item active' : 'page-item'"
        >
          <a class="page-link" @click="changePage(n)">{{ n }}</a>
        </li>
        <li class="page-item">
          <a class="page-link" @click="changePage(true)">&raquo;</a>
        </li>
      </ul>
    </div>
    <a
      v-if="enableUpload"
      class="btn btn-success"
      data-toggle="collapse"
      href="#newDocument"
      role="button"
      aria-expanded="false"
      aria-controls="collapse"
    >
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 16 16"
        class="bi bi-plus-circle-fill"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"
        /></svg
      >&#32;
      <span class="text">New Document</span>
    </a>
    <br />
    <br />
    <div class="collapse" id="newDocument">
      <div class="jumbotron jumbotron-fluid">
        <form class="container">
          <div class="form-row">
            <div class="form-group col">
              <label for="expiryDate">Documents:</label>
              <b-form-file
                v-model="file"
                ref="documentUpload"
                id="documentUpload"
              ></b-form-file>
            </div>
            <div class="form-group col">
              <label for="expiryDate">Expiry Date:</label>
              <base-input addon-left-icon="ni ni-calendar-grid-58">
                <flat-picker
                  slot-scope="{ focus, blur }"
                  @on-open="focus"
                  @on-close="blur"
                  :config="{
                    allowInput: true,
                    altInput: true,
                    altFormat: 'd-m-Y',
                  }"
                  class="form-control datepicker"
                  v-model="expiryDate"
                  id="expiryDate"
                  ref="expiryDate"
                >
                </flat-picker>
              </base-input>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col">
              <label for="documentType">Document Type:</label>
              <select class="form-control" ref="documentType" id="documentType">
                <optgroup label="Buildings">
                  <option>PPAR</option>
                  <option>Gas Cert</option>
                  <option>Electric Cert</option>
                  <option>EPC</option>
                  <option>Floorplans</option>
                  <option>Fire Alarm Cert</option>
                  <option>Emergency Lighting Cert</option>
                  <option>PAT Cert</option>
                  <option>Fire Risk Assessment</option>
                  <option>HMO Final Licence</option>
                  <option>HMO Draft Licence</option>
                  <option>HMO Application</option>
                  <option>HMO Payment Proof</option>
                  <option>HMO Document</option>
                  <option>Council Document</option>
                </optgroup>
                <optgroup label="People">
                  <option>Passport</option>
                  <option>Driving Licence</option>
                  <option>Proof of ownership</option>
                  <option>Building insurance</option>
                  <option>Terms of Business</option>
                </optgroup>
                <optgroup label="Other">
                  <option>Other Documents</option>
                </optgroup>
              </select>
            </div>
            <div class="form-group col"></div>
          </div>
          <button
            type="submit"
            class="btn btn-success"
            @click.prevent="uploadDocument()"
          >
            Upload
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import getEnv from "@/utils/env";
import app_config from "../../app_config.json";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { ExportToCsv } from "export-to-csv";

export default {
  name: "Documents",
  components: {
    flatPicker,
  },
  data() {
    return {
      documents: "",
      checkedDocuments: [],
      pages: 1,
      current_page: 1,
      file_api_url: app_config[getEnv("VUE_APP_ENV")].file_api_url,
      get_file_api_url: app_config[getEnv("VUE_APP_ENV")].get_file_api_url,
      file: null,
      expiryDate: "",
      archived: false,
    };
  },
  props: {
    url: {
      type: String,
      default: "",
    },
    enableUpload: {
      type: Boolean,
      default: true,
    },
    token: String,
  },
  methods: {
    async uploadDocument() {
      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: true,
        onCancel: this.onCancel,
      });

      var formData = new FormData();
      formData.append("file", this.file);
      formData.append("author", this.$auth.user.name);
      formData.append("expiryDate", this.expiryDate);
      formData.append("documentType", this.$refs.documentType.value);
      await axios.post(
        this.file_api_url + this.$props.url + "/" + this.file.name,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${this.token}`,
          },
        }
      );

      this.refresh(loader);
    },
    async deleteDocument() {
      const url = this.file_api_url;

      let message = "are you sure you want to delete?</br></br>";

      for (const file of this.checkedDocuments) {
        message += "<ul>";
        message += "<li>" + file + "</li>";
        message += "</ul>";
      }

      this.$dialog
        .confirm(message, {
          verification: "confirm",
          type: "hard",
          okText: "Delete",
          html: true,
        })
        .then(() => {
          let loader = this.$loading.show({
            // Optional parameters
            container: this.fullPage ? null : this.$refs.formContainer,
            canCancel: true,
            onCancel: this.onCancel,
          });

          axios
            .delete(url, {
              headers: {
                Authorization: `Bearer ${this.token}`, // send the access token through the 'Authorization' header
              },
              params: {
                files: this.checkedDocuments.join(),
              },
            })
            .then(() => {
              this.checkedDocuments = [];
              this.refresh(loader);
            });
        });
    },
    async archiveDocument() {
      const url = this.file_api_url + "archive/";

      let message = "are you sure you want to archive?</br></br>";

      for (const file of this.checkedDocuments) {
        message += "<ul>";
        message += "<li>" + file + "</li>";
        message += "</ul>";
      }

      this.$dialog
        .confirm(message, {
          verification: "confirm",
          type: "hard",
          okText: "Archive",
          html: true,
        })
        .then(() => {
          let loader = this.$loading.show({
            // Optional parameters
            container: this.fullPage ? null : this.$refs.formContainer,
            canCancel: true,
            onCancel: this.onCancel,
          });

          axios
            .patch(
              url,
              {},
              {
                headers: {
                  Authorization: `Bearer ${this.token}`, // send the access token through the 'Authorization' header
                },
                params: {
                  files: this.checkedDocuments.join(),
                },
              }
            )
            .then(() => {
              this.refresh(loader);
            });
        });
    },
    async downloadDocument() {
      window.open(
        this.get_file_api_url.slice(0, -1) +
          "?files=" +
          this.checkedDocuments.join() +
          "&Authorization=" +
          this.token
      );
    },
    async getDocuments(loader, token = this.token, params = {}) {

      params["page"] = this.current_page;

      if (this.$refs.searchFilename.value.trim().length > 0) {
        params["file_name"] = this.$refs.searchFilename.value.trim();
      }

      if (this.$refs.searchAuthor.value.trim().length > 0) {
        params["author"] = this.$refs.searchAuthor.value.trim();
      }

      if (this.$refs.searchUploadFromDate.value.trim().length > 0) {
        params[
          "upload_date_from"
        ] = this.$refs.searchUploadFromDate.value.trim();
      }

      if (this.$refs.searchUploadToDate.value.trim().length > 0) {
        params["upload_date_to"] = this.$refs.searchUploadToDate.value.trim();
      }

      if (this.$refs.searchExpiryFromDate.value.trim().length > 0) {
        params[
          "expiry_date_from"
        ] = this.$refs.searchExpiryFromDate.value.trim();
      }

      if (this.$refs.searchExpiryToDate.value.trim().length > 0) {
        params["expiry_date_to"] = this.$refs.searchExpiryToDate.value.trim();
      }

      if (this.$refs.searchDocumentType.value != "All") {
        params["document_type"] = this.$refs.searchDocumentType.value.trim();
      }

      if (!this.archived) {
        params["archived"] = this.archived;
      }

      //Use Axios to make a call to the API
      const documentsData = await axios.get(
        this.file_api_url + this.$props.url,
        {
          headers: {
            Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
          },
          params: params,
        }
      );

      this.documents = documentsData["data"];
      this.pages = this.documents[0] ? this.documents[0].no_pages : 0;

      loader.hide();

      return this.documents[0] ? this.documents[0].count : 0;
    },
    async changePage(up) {
      if (typeof up == "boolean") {
        if (up) {
          this.current_page === this.pages ? null : this.current_page++;
        } else {
          this.current_page === 1 ? null : this.current_page--;
        }
      } else {
        this.current_page = up;
      }
      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: true,
        onCancel: this.onCancel,
      });
      this.getDocuments(loader);
    },
    async refresh(loader, token = this.token, params = {}) {
      this.getDocuments(loader, token, params);
    },
    async clearSearch() {
      this.$refs.searchFilename.value = "";
      this.$refs.searchAuthor.value = "";
      this.$refs.searchUploadToDate.value = "";
      this.$refs.searchUploadFromDate.value = "";
      this.$refs.searchExpiryToDate.value = "";
      this.$refs.searchExpiryFromDate.value = "";
      this.$refs.searchDocumentType.value = "All";

      this.search();
    },
    async search() {
      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: true,
        onCancel: this.onCancel,
      });

      this.getDocuments(loader);
    },
    downloadCSV() {
      const options = {
        fieldSeparator: ",",
        quoteStrings: '"',
        decimalSeparator: ".",
        showLabels: true,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        filename: "certificates",
        // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
      };

      const csvExporter = new ExportToCsv(options);

      csvExporter.generateCsv(this.documents);
    },
  },
};
</script>
