<template>
  <div class="inventory">
    <h3>
      <i class="bi bi-journal-check"></i>
      Inventory
    </h3>
    <table
      class="table table-hover"
      style="table-layout: fixed; word-wrap: break-word;"
    >
      <thead class="thead-dark">
        <tr>
          <th style="width: 30%"></th>
          <th style="width: 50%">Description</th>
          <th style="width: 20%"></th>
        </tr>
      </thead>
      <tbody v-for="item in inventory" :key="item.id">
        <tr>
          <td>
            <b>ID</b>: {{ item.id }} <br />
            <b>Author</b>: {{ item.author }} <br />
            <b>Creation Date</b>:
            {{
              new Date(item.creation_date)
                .toLocaleDateString("en-GB")
                .replaceAll("/", "-") +
                " " +
                new Date(item.creation_date)
                  .toLocaleTimeString("en-GB")
                  .slice(0, -3)
            }}
            <b>Inventory Date</b>:
            {{
              item.inventory_date
                ? new Date(item.inventory_date)
                    .toLocaleDateString("en-GB")
                    .replaceAll("/", "-")
                : ""
            }}
          </td>
          <td>
            {{ item.description }}<br /><br />
            <div v-for="file in item.files" :key="file">
              <router-link
                :to="{
                  name: 'Documents',
                  query: { file: file },
                }"
              >
                <button class="btn btn-default" type="button">
                  {{ file }}
                </button>
              </router-link>
              <p></p>
            </div>
          </td>
          <td>
            <a
              class="btn btn-primary"
              data-toggle="collapse"
              :href="'#item-' + item.id"
              role="button"
              aria-expanded="false"
              :aria-controls="'item-' + item.id"
              v-b-tooltip.hover.top
              title="Edit item"
              hidden
            >
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                class="bi bi-pencil-fill"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"
                />
              </svg>
            </a>
            <b-button
              v-b-tooltip.hover.top
              title="Delete item"
              id="tooltip-target-1"
              class="btn btn-danger"
              @click="deleteInventory(item.id)"
            >
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                class="bi bi-trash-fill"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z"
                />
              </svg>
            </b-button>
          </td>
        </tr>
        <tr class="collapse" :id="'item-' + item.id">
          <td colspan="5">
            <div class="jumbotron jumbotron-fluid">
              <form class="container">
                <div class="form-row">
                  <div class="form-group col">
                    <label :for="'item-' + item.id">Description: </label>
                    <textarea
                      class="form-control"
                      :ref="'itemDescription-' + item.id"
                      :value="item.description"
                      id="description"
                      rows="8"
                      cols="80"
                    ></textarea>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col">
                    <label for="itemDate">Inventory Date: </label>
                    <base-input addon-left-icon="ni ni-calendar-grid-58">
                      <flat-picker
                        slot-scope="{ focus, blur }"
                        @on-open="focus"
                        @on-close="blur"
                        :config="{
                          allowInput: true,
                          altInput: true,
                          altFormat: 'd-m-Y',
                        }"
                        class="form-control datepicker"
                        v-model="item.item_date"
                        id="itemDate"
                        :ref="'itemDate-' + item.id"
                      >
                      </flat-picker>
                    </base-input>
                  </div>
                </div>
                <button
                  type="submit"
                  class="btn btn-success"
                  @click.prevent="edititem(item.id)"
                >
                  Save
                </button>
              </form>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <a
      class="btn btn-success"
      data-toggle="collapse"
      href="#newitem"
      role="button"
      aria-expanded="false"
      aria-controls="collapse"
    >
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 16 16"
        class="bi bi-plus-circle-fill"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"
        /></svg
      >&#32;
      <span class="text">item</span>
    </a>
    <div class="collapse" id="newitem">
      <div class="jumbotron jumbotron-fluid">
        <form class="container">
          <div class="form-row">
            <div class="form-group col">
              <label for="itemDate">Inventory Date: </label>
              <base-input addon-left-icon="ni ni-calendar-grid-58">
                <flat-picker
                  slot-scope="{ focus, blur }"
                  @on-open="focus"
                  @on-close="blur"
                  :config="{
                    allowInput: true,
                    altInput: true,
                    altFormat: 'd-m-Y',
                  }"
                  class="form-control datepicker"
                  v-model="itemDate"
                  id="itemDate"
                  ref="itemDate"
                >
                </flat-picker>
              </base-input>
            </div>
            <div class="form-group col">
              <label for="itemFile">Files : </label>
              <b-form-file
                multiple
                v-model="itemFile"
                ref="itemFile"
                id="itemFile"
              ></b-form-file>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col">
              <label for="description">Description: </label>
              <textarea
                class="form-control"
                ref="description"
                id="description"
                rows="8"
                cols="80"
              ></textarea>
            </div>
          </div>
          <button
            type="submit"
            class="btn btn-success"
            @click.prevent="createInventory"
          >
            Create
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import getEnv from "@/utils/env";
import app_config from "../../app_config.json";

export default {
  data() {
    return {
      inventory: [],
      itemFile: [],
      inventory_api_url: app_config[getEnv("VUE_APP_ENV")].inventory_api_url,
      file_api_url: app_config[getEnv("VUE_APP_ENV")].file_api_url,
      itemDate: null,
    };
  },
  components: {
    flatPicker,
  },
  props: {
    token: String,
    callApi: Function,
    building_id: Number,
  },
  methods: {
    async refresh(token = this.token) {
      this.getInventory(token);
    },
    async getInventory(token = this.token) {
      //Use Axios to make a call to the API
      let url = this.inventory_api_url + "building/" + this.$props.building_id;
      const inventoryData = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
        },
      });

      this.inventory = inventoryData["data"];
      return this.inventory.length;
    },
    async createInventory() {
      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: true,
        onCancel: this.onCancel,
      });

      let payload = {
        building_id: this.$props.building_id,
        author: this.$auth.user.name,
        description: this.$refs.description.value,
        inventory_date: this.itemDate,
        files: "{}",
      };

      const url =
        this.inventory_api_url + "building/" + this.$props.building_id;

      if (this.itemFile) {
        this.uploadDocument();
        let fileNames = this.itemFile.map((file) => file.name);
        let filespayload = "{";
        for (var file of fileNames) {
          filespayload += '"' + file + '",';
        }
        filespayload = filespayload.slice(0, -1) + "}";
        payload["files"] = filespayload;
      }

      axios
        .post(url, payload, {
          headers: {
            Authorization: `Bearer ${this.token}`, // send the access token through the 'Authorization' header
          },
        })
        .then(() => {
          this.callApi(loader);
        });
    },
    async deleteInventory(id) {
      const url = this.inventory_api_url + id;

      this.$dialog
        .confirm("are you sure?", {
          verification: "confirm",
          type: "hard",
          okText: "Delete",
        })
        .then(() => {
          let loader = this.$loading.show({
            // Optional parameters
            container: this.fullPage ? null : this.$refs.formContainer,
            canCancel: true,
            onCancel: this.onCancel,
          });

          axios
            .delete(url, {
              headers: {
                Authorization: `Bearer ${this.token}`, // send the access token through the 'Authorization' header
              },
            })
            .then(() => {
              loader.hide();
              this.callApi(loader);
            });
        });
    },
    uploadDocument() {
      for (var file of this.itemFile) {
        var formData = new FormData();
        formData.append("file", file);
        formData.append("author", this.$auth.user.name);
        formData.append("expiryDate", "");
        formData.append("documentType", "Other Documents");
        axios.post(
          this.file_api_url +
            "owner/building/id/" +
            this.$props.building_id +
            "/" +
            file.name,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${this.token}`,
            },
          }
        );
      }
    },
  },
};
</script>
