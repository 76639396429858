<template>
  <div class="profile">
    <section class="section-shaped my-0">
      <div class="shape shape-style-1 shape-default shape-skew">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <h1 class="display-1 text-secondary">
        <i class="fa fa-user-circle" aria-hidden="true"></i> Profile
      </h1>
      <div class="container">
        <div class="row">
          <div class="container jumbotron col-md-auto">
            <table>
              <thead>
                <img :src="$auth.user.picture" />
              </thead>
              <tbody class="text-left">
                <tr>
                  <td><br /></td>
                </tr>
                <tr>
                  <td>Name: {{ $auth.user.name }}</td>
                </tr>
                <tr>
                  <td>Email: {{ $auth.user.email }}</td>
                </tr>
                <tr>
                  <td>Company: {{ user.app_metadata.company }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-1"></div>
          <div class="col text-left">
            <div class="container jumbotron">
              <h2>Change Password</h2>
              <form>
                <div class="form-row">
                  <div class="form-group col">
                    <div
                      :hidden="passwordWarning.length < 1"
                      class="alert alert-danger"
                      role="alert"
                    >
                      {{ passwordWarning }}
                    </div>
                    <div
                      :hidden="passwordSuccess.length < 1"
                      class="success alert-success"
                      role="alert"
                    >
                      {{ passwordSuccess }}
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col">
                    <label for="password">Password</label>
                    <input
                      type="password"
                      class="form-control"
                      id="password"
                      ref="password"
                      name="password"
                      @keyup="checkPwd"
                      placeholder="Password"
                    />
                  </div>
                  <div class="form-group col">
                    <label for="passwordConfirm">Confirm Password</label>
                    <input
                      type="password"
                      class="form-control"
                      id="passwordConfirm"
                      ref="passwordConfirm"
                      name="passwordConfirm"
                      @keyup="checkPwd"
                      placeholder="Confirm Password"
                    />
                  </div>
                </div>

                <button
                  type="submit"
                  class="btn btn-primary"
                  @click.prevent="updateUser"
                  :disabled="!samePwd"
                >
                  Save Changes
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import app_config from "../../app_config.json";
import getEnv from "@/utils/env";

export default {
  name: "external-api",
  data() {
    return {
      user: "",
      samePwd: false,
      passwordWarning: "",
      passwordSuccess: "",
      user_api_url: app_config[getEnv("VUE_APP_ENV")].user_api_url,
      token: "",
    };
  },
  created() {
    this.callApi();
  },
  methods: {
    checkPwd() {
      if (this.$refs.password.value != this.$refs.passwordConfirm.value) {
        this.samePwd = false;
        this.passwordWarning = "Passwords do not match!";
      } else if (this.$refs.password.value.length < 8) {
        this.samePwd = false;
        this.passwordWarning = "Password must be atleast 8 characters!";
      } else {
        this.samePwd = true;
        this.passwordWarning = "";
      }
    },
    async updateUser() {
      const fd = new FormData();
      fd.append("password", this.$refs.password.value);

      const { data } = await axios.patch(
        this.user_api_url + this.token + "/password",
        fd,
        { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
      );
      if (data["statusCode"] == 400) {
        this.passwordWarning = data["message"];
      } else {
        this.passwordWarning = "";
        this.passwordSuccess = "Changes saved successfully";
        this.$refs.password.value = "";
        this.$refs.passwordConfirm.value = "";
      }
    },
    async callApi() {
      // Get the access token from the auth wrapper
      this.token = await this.$auth.getTokenSilently();

      // Use Axios to make a call to the API
      const { data } = await axios.get(this.user_api_url + this.token, {});

      this.user = data;
    },
  },
};
</script>
