<template>
  <div class="utilities">
    <h3><i class="bi bi-lightbulb"></i> Utilities</h3>
    <br />
    <h4>Suppliers</h4>

    <form>
      <div class="form-row">
        <div class="form-group col">
          <p>Gas:</p>
          <b v-if="!edit">{{ gas ? gas : "None" }}</b>
          <input
            v-else
            type="text"
            ref="gas_supplier"
            class="form-control"
            :value="gas"
          />
        </div>
        <div class="form-group col">
          <p>Electric:</p>
          <b v-if="!edit">{{ electric ? electric : "None" }}</b>
          <input
            v-else
            type="text"
            ref="electric_supplier"
            class="form-control"
            :value="electric"
          />
        </div>
        <div class="form-group col">
          <p>Water:</p>
          <b v-if="!edit">{{ water ? water : "None" }}</b>
          <input
            v-else
            type="text"
            ref="water_supplier"
            class="form-control"
            :value="water"
          />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col">
          <button
            type="submit"
            class="btn btn-success"
            @click.prevent="editSuppliers()"
            :hidden="!edit"
          >
            Save Changes
          </button>
          <button class="btn btn-primary" type="button" @click="editText()">
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              class="bi bi-pencil-fill"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"
              /></svg
            >&#32;
            <span class="text">Edit</span>
          </button>
        </div>
      </div>
    </form>

    <br />
    <h4>Readings</h4>
    <a
      class="btn btn-success"
      data-toggle="collapse"
      href="#newNote"
      role="button"
      aria-expanded="false"
      aria-controls="collapse"
    >
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 16 16"
        class="bi bi-plus-circle-fill"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"
        /></svg
      >&#32;
      <span class="text">New Reading</span>
    </a>
    <div class="collapse" id="newNote">
      <div class="jumbotron jumbotron-fluid">
        <form>
          <div class="form-row">
            <div class="form-group col">
              <label for="utilityDate">Read Date: </label>
              <base-input addon-left-icon="ni ni-calendar-grid-58">
                <flat-picker
                  slot-scope="{ focus, blur }"
                  @on-open="focus"
                  @on-close="blur"
                  :config="{
                    allowInput: true,
                    altInput: true,
                    altFormat: 'd-m-Y',
                  }"
                  class="form-control datepicker"
                  v-model="utilityDate"
                  id="utilityDate"
                  ref="utilityDate"
                >
                </flat-picker>
              </base-input>
            </div>
            <div class="form-group col">
              <label for="utilityType">Type:</label>
              <select
                class="form-control"
                id="utilityType"
                ref="utilityType"
              >
                <option>Open</option>
                <option>Close</option>
                <option>Other</option>
              </select>
            </div>
            <div class="form-group col">
              <label for="utility">Utility:</label>
              <select
                class="form-control"
                id="utility"
                ref="utility"
              >
                <option>Gas</option>
                <option>Electric</option>
                <option>Water</option>
              </select>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col">
              <label for="utilityValue">Value:</label>
              <input ref="utilityValue" class="form-control" type="number" />
            </div>
            <div class="form-group col"></div>
            <div class="form-group col"></div>
          </div>
          <button
            type="submit"
            class="btn btn-success"
            @click.prevent="createReading"
          >
            Create
          </button>
        </form>
      </div>
    </div>
    <br /><br />
    <table class="table table-hover">
      <thead class="thead-dark">
        <tr>
          <th style="width: 20%">Date</th>
          <th style="width: 30%">Supplier</th>
          <th style="width: 20%">Utility</th>
          <th style="width: 20%">Type</th>
          <th style="width: 10%">Value</th>
          <th style="width: 10%"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="read in readings" :key="read.id">
          <td>
            {{
              read.date
                ? new Date(read.date)
                    .toLocaleDateString("en-GB")
                    .replaceAll("/", "-")
                : ""
            }}
          </td>
          <td>
            {{ read.supplier }}
          </td>
          <td>{{ read.utility }}</td>
          <td>
            {{ read.type }}
          </td>
          <td>{{ read.value }}</td>
          <td><button
                          class="btn btn-danger"
                          type="button"
                          @click="deleteReading(read.id)"
                          v-b-tooltip.hover.top
                          title="Delete Reading"
                        >
                          <svg
                            width="1em"
                            height="1em"
                            viewBox="0 0 16 16"
                            class="bi bi-trash-fill"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z"
                            />
                          </svg>
                        </button></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from "axios";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import getEnv from "@/utils/env";
import app_config from "../../app_config.json";

export default {
  data() {
    return {
      edit: false,
      readings: [],
      buildings_api_url: app_config[getEnv("VUE_APP_ENV")].buildings_api_url,
      utilities_api_url: app_config[getEnv("VUE_APP_ENV")].utilities_api_url,
      utilityDate: null
    };
  },
  components: {
    flatPicker,
  },
  props: {
    gas: {
      type: String,
      default: "None",
    },
    electric: {
      type: String,
      default: "None",
    },
    water: {
      type: String,
      default: "None",
    },
    token: String,
    callApi: Function,
    building_id: Number,
  },
  methods: {
    async editText() {
      if (this.edit) {
        this.edit = false;
      } else {
        this.edit = true;
      }
    },
    async refresh(loader, token = this.token) {
      this.getReadings(loader, token);
    },
    async getReadings(loader, token = this.token) {
      //Use Axios to make a call to the API
      const documentsData = await axios.get(
        this.utilities_api_url +
          "building/" +
          this.$props.building_id,
        {
          headers: {
            Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
          },
        }
      );

      this.readings = documentsData["data"];

      loader.hide();
    },
    async createReading() {
      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: true,
        onCancel: this.onCancel
      })

      let defect = {
        date: this.utilityDate,
        supplier: this.$props[this.$refs.utility.value.toLowerCase()],
        type: this.$refs.utilityType.value,
        utility: this.$refs.utility.value,
        value: this.$refs.utilityValue.value
      }

      axios
          .post(
              this.utilities_api_url + 'building/' + this.building_id,
              defect,
              {
                headers: {
                  Authorization: `Bearer ${this.token}` // send the access token through the 'Authorization' header
                }
              }
          )
          .then(
              () => {
                this.refresh(loader)
              }
          )

      this.refresh(loader)
    },
    async deleteReading(id) {
      const url = this.utilities_api_url + id

this.$dialog
        .confirm("are you sure?", {
          verification: "confirm",
          type: "hard",
          okText: "Delete",
        })
        .then(() => {

        let loader = this.$loading.show({
          // Optional parameters
          container: this.fullPage ? null : this.$refs.formContainer,
          canCancel: true,
          onCancel: this.onCancel
        })

        axios
            .delete(url, {
              headers: {
                Authorization: `Bearer ${this.token}` // send the access token through the 'Authorization' header
              }
            })
            .then(
                () => {
                  this.refresh(loader)
                }
            )
      })
    }, 
    async editSuppliers() {
      this.$dialog
        .confirm("are you sure?", {
          clicksCount: 3,
          type: "soft",
          okText: "Edit",
        })
        .then(() => {
          let loader = this.$loading.show({
            // Optional parameters
            container: this.fullPage ? null : this.$refs.formContainer,
            canCancel: true,
            onCancel: this.onCancel,
          });

          let payload = {
            gas_supplier: `'${this.$refs["gas_supplier"].value.trim()}'`,
            electric_supplier: `'${this.$refs[
              "electric_supplier"
            ].value.trim()}'`,
            water_supplier: `'${this.$refs["water_supplier"].value.trim()}'`,
          };

          axios
            .patch(this.buildings_api_url + this.$props.building_id, payload, {
              headers: {
                Authorization: `Bearer ${this.token}`, // send the access token through the 'Authorization' header
              },
            })
            .then(() => {
              this.edit = false;
              this.callApi(loader);
            });
        });
    },
  },
};
</script>
